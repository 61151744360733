import { FunctionComponent, ReactNode, createContext, useContext, useState } from "react";
import { Session, initialSession } from "../@types/session.d";

export const SessionContext = createContext<[Session, (session: Session) => void]>([initialSession, () => {}]);
export const useSessionContext = () => useContext(SessionContext);

interface Props {
    children: ReactNode;
}

export const SessionProvider: FunctionComponent<Props> = ({children}) => {
  const [sessionState, setSessionState] = useState(initialSession);
  const defaultSessionContext: [Session, typeof setSessionState]  = [sessionState, setSessionState];

  return (
    <SessionContext.Provider value={defaultSessionContext}>
      {children}
    </SessionContext.Provider>
  );
}

export default SessionProvider;