import { ListGroup, Nav, NavDropdown, Navbar } from "react-bootstrap";

const PanelAside = () => {
    return (
        <>
            <div className="mt-5">
                <p className="panel-aside-title">PANEL</p>
                <ListGroup variant="flush">
                    <ListGroup.Item action href="/#/panel/home"><i className="menu-icon bi bi-house"></i> Dashboard</ListGroup.Item>
                    <ListGroup.Item action href="/#/panel/users"><i className="menu-icon bi bi-people"></i> Usuarios</ListGroup.Item>
                </ListGroup>
            </div>
        </>
    )
}
export default PanelAside;