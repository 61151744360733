import { useEffect, useState } from "react";
import axios from "axios";

type GetUsersResponse = {
    data: [];
};

type APIProps = {
    url : string,
    token? : string,
}

const useAPI = ({url,token} : APIProps) => {
    const [data, setData] = useState<GetUsersResponse>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    
    useEffect(() => {
        //console.warn("AXIOS-get...");
        axios.get<GetUsersResponse>(url, config)
        .then((response) => {
            setData(response.data);
            //console.warn("AXIOS-get... done");
        })
        .catch((error) => {
            console.log(error);
            setError(error);
        });
    }, []);
    return { data, loading, error };
};

export default useAPI;