import { Button, Card, Col, Row } from "react-bootstrap";
import './StatsBox.scss'

type Props = {
    title: String,
    description: String
    icon: string
}
const StatsBox = ({title, description, icon} : Props) => {
    return (
        <Card className="stats-box align-self-stretch w-100">
            <Card.Body>
                <span className="stats-box--bg-icon"><i className={icon}></i></span>
                <Row className="align-items-center">
                    <Col className="text-center">
                        <Card.Title className="fs-1 fw-semibold">{ title }</Card.Title>
                        <Card.Text className="mb-0 opacity-75">
                            {description}
                        </Card.Text>
                    </Col>
                </Row>
            </Card.Body>
            {/*<Card.Footer className="bg-white py-0 px-0 text-end">
                <Button variant="link" size="sm">Ver detalles</Button>
    </Card.Footer>*/}
        </Card>
    )
}

export default StatsBox;