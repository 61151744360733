import { Col, Container, Row } from "react-bootstrap";
import PanelAside from "../../../components/AdminPanel/PanelAside/PanelAside";
import PanelLayout from "../../../components/AdminPanel/PanelLayout/PanelLayout";
import UsersTable from "../../../components/AdminPanel/UsersTable/UsersTable";
import { useEffect, useState } from "react";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../../firebase";
import { getAuth } from "firebase/auth";

const UsersPanel = () => {

    const [usersData, setUsersData] = useState<any[]>([])
    
    const getData = async () => {
        const logsRef = collection(db, "users");
        const q = query(logsRef, orderBy("displayName","asc"));
        const querySnapshot = await getDocs(q);
        const result : any[] = [];
        querySnapshot.forEach((doc) => {
            result.push({
                ...doc.data(),
                uid: doc.id,
            });
        });
        setUsersData(result);
    }
    useEffect(() => {
        getData();
    },[])
    

    return (
        <PanelLayout leftbar={<PanelAside />}>
            <>
            <Container fluid="md" className="mt-5">
                <Row>
                    <Col>
                        <UsersTable data={usersData} onHandleRefresh={getData} />
                    </Col>
                </Row>
            </Container>
            </>
        </PanelLayout>
    )
}

export default UsersPanel;