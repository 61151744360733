import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import './Footer.scss';

const Footer = () => {
    return (
        <footer className="footer">
            <span className="footer--background"></span>
            <Container fluid>
                <Row className="align-items-center justify-content-center">
                    <Col xs='2' lg='1'>
                        <span className="lg-investments"></span>
                    </Col>
                    <Col xs='2' lg='1' className="py-1">
                        <span className="copyright">&copy; ATIKO <span className="year">{new Date().getFullYear()}</span></span>
                    </Col>
                    <Col xs='12' lg='6'>
                        <p>
                            <span className="icon icon--location"></span> Juan Ignacio Ramón 856, Col. Centro, Monterrey. Frente al Museo del Noreste
                        </p>
                        <Row className="footer--legals align-items-center">
                            <Col >
                                <sup>*</sup>Precios sujetos a disponibilidad.
                            </Col>
                            <Col>
                                <Button variant='text' href="#/aviso-de-privacidad">Aviso de Privacidad</Button>
                            </Col>
                            <Col>
                                <Button variant='text' href="#/aviso-de-no-discriminacion">Aviso de no discriminación</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs='3' lg='2'>
                        <Button href="https://renderinc.mx/" target="_blank" variant="text"><span className="lg-renderinc"></span></Button>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer;