import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap"
import { useSessionContext } from "../../../context/sessionContext"

const PanelHeader = () => {
    const [session] = useSessionContext()

    return(
        <Navbar expand="lg" fixed="top" className="bg-body-tertiary justify-content-between" bg="primary" data-bs-theme="dark">
            <Container>
                <Navbar.Brand href="/#/home" className="text-white fw-semibold">Atiko</Navbar.Brand>
            </Container>
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link href="" className="text-white">{ session.user?.displayName } &rsaquo;</Nav.Link>
                        <Nav.Link href="/#/home" className="text-white">Salir <i className="bi bi-power"></i></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default PanelHeader