import { Badge, Card, Col, Image, Row, Table } from "react-bootstrap"
import moment from 'moment'
import 'moment/locale/es'
import ExportButton from "../ExportButton/ExportButton";
import { useEffect, useState } from "react";
moment.locale('es');


type Props = {
    data: any[],
}

const LogTable = ({data} : Props) => {

    const [parsedData, setParsedData] = useState<any>()
    const getActionType = (action : string) => {
        let actionType = 'info';
        switch(action) {
            case 'REGISTER': actionType = 'success'; break;
            default: break;
        }
        return actionType;
    }

    useEffect(() => {
        const parsed = data.map((log, index) => {
            return {
                'Fecha': moment(log.created_at.toDate()).format('YYYY-MM-DD HH:mm:ss'),
                'Usuario': log.user.displayName,
                'Operación': log.action,
                'Acciones': log.message,
                'Ubicación': log.location || 'No disponible'
            }
        })
        setParsedData(parsed);
    },[data])

    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col>
                        Últimos movimientos 
                    </Col>
                    <Col className="mr-0 text-end">
                        <ExportButton label="Exportar" data={parsedData} customFileName='Atiko - ultimos movimientos'  />
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Table hover variant="light" size="lg" responsive>
                    <thead>
                        <tr>
                            <th><i className="bi bi-calendar"></i> Fecha</th>
                            <th><i className="bi bi-person"></i> Usuarios</th>
                            <th><i className="bi bi-activity"></i> Operación</th>
                            <th><i className="bi bi-activity"></i> Acciones</th>
                            <th><i className="bi bi-world"></i> Ubicación</th>
                        </tr>
                    </thead>
                    <tbody>
                       { data.map((log, index) => {
                            return (
                            <tr key={index}>
                                <td>{ moment(log.created_at.toDate()).calendar(null, { sameElse: 'DD MMM, YYYY - HH:mm' }) }</td>
                                <td><Image src={`https://ui-avatars.com/api/?size=48&name=${log.user.displayName}`} className="me-2" roundedCircle /> { log.user.displayName }</td>
                                <td><Badge bg={getActionType(log.action)}>{ log.action }</Badge></td>
                                <td>{ log.message }</td>
                                <td width={200}>{ log.location || 'No disponible' }</td>
                            </tr>);
                        })}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default LogTable