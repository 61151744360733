import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth"
import { FormEvent, useEffect, useState } from "react"
import { Button, Card, Col, Form, Row } from "react-bootstrap"
import { auth, db } from "../../../firebase"
import { UserType } from "../../../@types/user"
import { FirebaseError } from "firebase/app"
import { doc, serverTimestamp, setDoc } from "firebase/firestore"
import useLogger from "../../../hooks/useLogger"
import { useSessionContext } from "../../../context/sessionContext"

type Props = {
    selectedUser: UserType,
    onHandleCancel: () => void,
    onHandleClose: () => void,
}
type ResponseMessageType = {
    responseType: string,
    message: string,
}

const UsersForm = ({selectedUser, onHandleCancel, onHandleClose} : Props) => {

    const defaultUser = {
        uid: '',
        displayName: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        role: '',
    }
    const [user, setUser] = useState<UserType>(defaultUser)
    const [session] = useSessionContext()
    const [responseMessage, setResponseMessage] = useState<ResponseMessageType | null>();
    const [isLoading, setIsLoading] = useState(false);
    const logger = useLogger()

    useEffect(() => {
        setUser(selectedUser)
    },[])

    const register = async (user : UserType) => {
        setIsLoading(true);
        try {
            const newUser = await createUserWithEmailAndPassword(auth, user.email, user.password).then(userCredential => {
                const storedUser = userCredential.user;
                updateProfile(storedUser,{
                    displayName: user.displayName,
                })
                return userCredential
            });
            const docRef = doc(db,`users/${newUser.user.uid}`);
            setDoc(docRef, {
                displayName: user.displayName,
                email: user.email,
                role: user.role,
                created_at: serverTimestamp(),
            })
            setUser({
                ...user,
                uid: newUser.user.uid
            })
            setResponseMessage({responseType: 'success', message: 'Se ha creado correctamente el usuario.'});
            logger.log({ action: 'REGISTER', message: `Nuevo usuario registrado: ${newUser.user.email}` })
            onHandleClose()
        }
        catch (error) {
            const errorCode = (error as FirebaseError).code;
            switch (errorCode) {
                case 'auth/email-already-in-use':
                setResponseMessage({responseType: 'error', message: 'El correo electrónico ya está en uso.'});
                break;
                case 'auth/weak-password':
                setResponseMessage({responseType: 'error', message: 'La contraseña es demasiado débil.'});
                break;
                case 'auth/invalid-email':
                setResponseMessage({responseType: 'error', message: 'El correo electrónico es inválido.'});
                break;
                default:
                setResponseMessage({responseType: 'error', message: 'Se ha producido un error al crear el usuario.'});
                break;
            }
            setIsLoading(false);
        }

    }

    const handleCancel = () => {
        onHandleCancel();
    }
    const handleChange = (event : any) => {
        setUser({ 
            ...user, 
            [event.target.name] : event.target.value
        });
    }
    const handleSubmit = (e : FormEvent<HTMLFormElement>) => {
        console.warn("Submit...");
        e.preventDefault();
        if(user.uid.length){
            // update
            console.warn('Update...')
        }
        else {
            // register
            register(user)
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="displayName">
                <Form.Label><i className="bi bi-person"></i> Nombre</Form.Label>
                <Form.Control type="text" name="displayName" placeholder="Juan Pérez" value={user.displayName} onChange={handleChange} />
            </Form.Group>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label><i className="bi bi-envelope"></i> Email</Form.Label>
                        <Form.Control type="text" name="email" placeholder="micorreo@correo.com" value={user.email} onChange={handleChange} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="role">
                        <Form.Label><i className="bi bi-building"></i> Rol</Form.Label>
                        <Form.Select aria-label="Rol" name="role" onChange={handleChange} value={user.role}>
                            <option>Seleccione...</option>
                            <option value="admin">Administrador</option>
                            <option value="sales">Ventas</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            { (!user.uid.length || session.user?.uid === user.uid) &&          
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="password">
                            <Form.Label><i className="bi bi-lock"></i> Nueva Contraseña</Form.Label>
                            <Form.Control type="password" name="password" placeholder="******" value={user.password} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="passwordConfirmation">
                            <Form.Label><i className="bi bi-lock"></i> Confirmar Contraseña</Form.Label>
                            <Form.Control type="password" name="passwordConfirmation" placeholder="******" value={user.passwordConfirmation} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                </Row>
            }
            <Form.Group className="mb-3">
                <Button variant="primary" className="text-white" type="submit" disabled={isLoading}><i className="bi bi-save"></i> Guardar</Button>
                <Button variant="default" onClick={handleCancel}><i className="bi bi-cross"></i> Cancelar</Button>
            </Form.Group>
            { responseMessage && 
                <Row>
                    <Col>
                        <p className={responseMessage.responseType === 'success' ? 'text-success' : 'text-danger'}>{responseMessage.message}</p>
                    </Col>
                </Row>
            }
            <Form.Group controlId="uid">
                <Form.Control type="hidden" value={user.uid} onChange={handleChange} className="mt-4" readOnly />
            </Form.Group>
        </Form>
    )
}

export default UsersForm