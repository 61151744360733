import { Chart } from "react-google-charts";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { DateRangePicker, SelectPicker } from 'rsuite';
import PanelLayout from "../../../components/AdminPanel/PanelLayout/PanelLayout";
import StatsBox from "../../../components/AdminPanel/StatsBox/StatsBox";
import LogTable from "../../../components/AdminPanel/LogTable/LogTable";
import PanelAside from "../../../components/AdminPanel/PanelAside/PanelAside";
import { useEffect, useState } from "react";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import moment from 'moment'
import 'moment/locale/es'
import 'rsuite/DateRangePicker/styles/index.css';
import 'rsuite/SelectPicker/styles/index.css';
moment.locale('es');

type Filters = {
    user: string,
    date_range: Array<[]>,
}

const HomePanel = () => {

    const defaultUser = {
        user: '',
        date_range: [],
    }
    const [ logData, setLogData ] = useState<any[]>([])
    const [ graphData, setGraphData ] = useState<any[]>(["user","quantity"])
    const [ selectData, setSelectData ] = useState<any[]>([])
    const [ selectInitialData, setSelectInitialData ] = useState<any[]>([])
    const [ filters, setFilters ] = useState<Filters>(defaultUser);
    const [ stats, setStats ] = useState<any>()
    
    const getData = async () => {
        let q = null;
        if(filters.date_range.length){
            let startAt = filters.date_range[0];
            let endAt = filters.date_range[1];
            if(filters.user.length)
                q = query(
                    collection(db, "logs"), 
                    where("user_uid", "==", filters.user), 
                    where("created_at", ">=", startAt), 
                    where("created_at", "<=", endAt), 
                    orderBy("created_at","desc")
                );
            else
                q = query(
                    collection(db, "logs"), 
                    where("created_at", ">=", startAt), 
                    where("created_at", "<=", endAt), 
                    orderBy("created_at","desc")
                );
        }
        else {
            if(filters.user.length)
                q = query(
                    collection(db, "logs"), 
                    where("user_uid", "==", filters.user), 
                    orderBy("created_at","desc")
                );
            else
                q = query(
                    collection(db, "logs"), 
                    orderBy("created_at","desc")
                );
        }
        const querySnapshot = await getDocs(q);
        const result : any[] = [];
        querySnapshot.forEach((doc) => {
            result.push(doc.data());
        });
        setLogData(result);

        const resultGraphData = Array<any>(["user","quantity"]);
        const resultSelectData = Array<any>();
        result.forEach(r => {
            const a = resultGraphData.find(x => x[0] === r.user.displayName );
            if(a)
                a[1] = a[1]+1
            else{
                resultGraphData.push([r.user.displayName,1])
                resultSelectData.push({
                    label: r.user.displayName,
                    value: r.user.uid,
                });
            }
        })
        setGraphData(resultGraphData);
        /*setGraphData([
            ["Work", 11],
            ["Eat", 2],
            ["Commute", 2],
            ["Watch TV", 2],
            ["Sleep", 7],
        ])*/
        setSelectData(resultSelectData)
        if(!selectInitialData.length)
            setSelectInitialData(resultSelectData)
    }
    useEffect(() => {
        getData();
        setFilters({
            user: '',
            date_range: [],
        })
    },[])
    useEffect(() => {
        const counters = logData.reduce((acc, cur) => {
            const date = moment(cur.created_at?.toDate()).format('YYYYMMDD')
            if(!acc[date])
                acc[date] = 1;
            else
                acc[date] += 1;
            return acc
        },{})
        const sum = Object.values(counters).reduce((acc:number, cur:any) => acc + cur, 0);
        let dailyAccess = 0;
        if(counters && sum)
            dailyAccess = sum/Object.values(counters).length;
        setStats({
            ...stats,
            dailyAccess,
        })
    },[logData])
    useEffect(() => {
        getData();
    },[filters])

    const handleChangeFilters = ({filter, value} : any) => {
        setFilters({...filters, [filter]: value || (filter=='date_range' ? [] : '')});
    }

    return (
        <PanelLayout leftbar={<PanelAside />}>
            <>
            <Container fluid="md" className="mt-5">
                <Row className="d-flex align-items-stretch">
                    <Col className="d-flex align-self-stretch pb-3 ">
                        <StatsBox title={logData[0] ? moment(logData[0]?.created_at?.toDate()).fromNow() : '-'} description={'Último movimiento de usuarios'} icon="bi bi-calendar-check" />
                    </Col>
                    <Col className="d-flex align-self-stretch pb-3">
                        <StatsBox title={`${graphData.length-1} ${graphData.length-1 > 1 ? 'usuarios' : 'usuario' } `} description={'activos durante este periodo'} icon="bi bi-people" />
                    </Col>
                    <Col className="d-flex align-self-stretch pb-3">
                        <StatsBox title={`${stats?.dailyAccess.toFixed(1) || 0} /día`} description={'Promedio de accesos por día'} icon="bi bi-graph-up" />
                    </Col>
                </Row>
            </Container>
            <Container fluid="md" className="mt-3">
                <Row>
                    <Col className="mb-3" md={3}>
                        <DateRangePicker onChange={(value, e) => handleChangeFilters({ filter: 'date_range', value })} />
                    </Col>
                    <Col md={3}>
                        <SelectPicker label="Usuario" data={selectInitialData} style={{ width: 224 }} placeholder='Todos' onChange={(value, e) => handleChangeFilters({ filter: 'user', value })} />
                    </Col>
                </Row>
                { !filters.user.length &&
                    <Row>
                        <Col>
                            <Chart
                                chartType="PieChart"
                                data={graphData}
                                options={{title: 'Total de accesos'}}
                                width={"100%"}
                                height={"400px"}
                                />
                        </Col>
                    </Row>
                }
            </Container>
            <Container fluid="md" className="mt-3">
                <Row>
                    <Col>
                        <LogTable data={logData} />
                    </Col>
                </Row>
            </Container>
            </>
        </PanelLayout>
    )

}

export default HomePanel;