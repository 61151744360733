import React from 'react'
import './MasterPlan.scss'

import BuildingSpinner from '../BuildingSpinner/BuildingSpinner'

type Props = {
    autoplay: boolean,
    handleAutoplayCallback: () => void
}

const MasterPlan = ({autoplay, handleAutoplayCallback} : Props) => {

    
    return (
        <div className="masterplan view">
            <div className="masterplan--content">
                <BuildingSpinner autoplay={autoplay} handleAutoplayCallback={handleAutoplayCallback} />
            </div>
        </div>
    )
}

export default MasterPlan;