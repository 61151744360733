import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import { useEffect, useState } from "react";
import { useSessionContext } from "../context/sessionContext";
import useGeocoder from "./useGeocoder";

export type LogRegister = {
    action: string,
    message: string,
}


const useLogger = () => {
    const geolocation = useGeocoder();
    const [session] = useSessionContext();
    const [location, setLocation] = useState<string>('No disponible')

    const getLocation = async (pos : any) => {
        var crd = pos.coords;
        /*console.log("Your current position is:");
        console.log(`Latitude : ${crd.latitude}`);
        console.log(`Longitude: ${crd.longitude}`);
        console.log(`More or less ${crd.accuracy} meters.`);*/
        geolocation.getAddress({lat: crd.latitude, lng: crd.longitude}).then((result:any) => {
            setLocation(result);
        })
        return `${crd.latitude} ${crd.longitude}, ${crd.accuracy} aprox.`
    }
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.permissions
            .query({ name: "geolocation" })
            .then(function (result) {
                //console.log(result);
                if (result.state === "granted") {
                    navigator.geolocation.getCurrentPosition(getLocation, 
                        (e) => console.warn('error',e), 
                        {
                            enableHighAccuracy: true,
                            timeout: 5000,
                            maximumAge: 0,
                        }
                    );
                }
                else if(result.state === "prompt"){
                    navigator.geolocation.getCurrentPosition(getLocation, 
                        (e) => console.warn('error',e), 
                        {
                            enableHighAccuracy: true,
                            timeout: 5000,
                            maximumAge: 0,
                        }
                    );
                }
            });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    },[])
    const log = async ({action, message} : LogRegister) => {
        try {
            //console.warn('state location:',location)
            const docRef = await addDoc(collection(db, "logs"),{
                user_uid: session.user?.uid,
                user: {
                    uid: session.user?.uid,
                    displayName: session.user?.displayName,
                },
                action: action,
                message: message,
                created_at: serverTimestamp(),
                location: location
            });
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    } 
    
    return { log }
}

export default useLogger;