import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import VideoTransition from '../VideoTransition/VideoTransition';
import './BuildingSpinner.scss'
import { ProjectContext } from '../../context/projectContext';

type Props = {
    autoplay: boolean,
    handleAutoplayCallback: () => void
}

const BuildingSpinner = ({autoplay, handleAutoplayCallback} : Props) => {

    const context = useContext(ProjectContext);

    const ref = useRef<HTMLInputElement>(null);
    const data = context?.project.assets.spinner;
    const [currentIdx, setCurrentIdx] = useState(data.sides.length - 3 || 0);
    //const [frame,setFrame] = useState('');
    const [transition,setTransition] = useState('');
    const [imageLoader,setImageLoader] = useState('');
    const [isPlaying, setIsPlaying] = useState(false);
    const [isPlayable, setIsPlayable] = useState(false);

    const left = () => (currentIdx-1 < 0) ?  data.sides.length - 1 : currentIdx-1
    const right = () => (currentIdx+1 > data.sides.length - 1) ? 0 : currentIdx+1
    const goLeft = () => setCurrentIdx(left());
    const goRight = () => setCurrentIdx(right());
    const goTop = () => setCurrentIdx(-1);
    const reset = () => {
        setCurrentIdx(data.sides.length - 3 || 0);
        spinBuilding('right');
    }


    const startTransition = () => {
        setIsPlaying(true);
        ref.current?.classList.add('is-spinning');
    }

    useEffect(() => {
        ref.current?.classList.remove('is-spinning');
        if(currentIdx=== -1)
            setTimeout(() => {
                window.location.hash = '#/niveles';
            },2500)
    },[currentIdx])

    useEffect(() => {
        if(isPlayable)
            startTransition();
    },[isPlayable])

    const spinBuilding = (dir: string) => {
        if(!dir) return;

        // prepare frame and preload
        setIsPlayable(false);
        setImageLoader(data.sides[currentIdx].endFrame);
        setTimeout(() => {
            // prepare dir-transition and play
            if(dir==='top'){
                setTransition(data.sides[currentIdx].transitions['overhead']);
                goTop();
            }
            else{
                setTransition(data.sides[currentIdx].transitions[ dir === 'left' ? 'left' : 'right']);
                dir === 'left' ? goLeft() : goRight();
            }
            // change currentIndex
            ref.current?.classList.remove('is-overhead');
        },500)
    }

    useEffect(() => {
        if(autoplay){
            reset();
            spinBuilding('right');
        }
    },[autoplay])

    useEffect(() => {
        const imageUrls = data.sides.map((s:any) => s.endFrame);
        const preLoadImages = () => {
            imageUrls.forEach((imageUrl : string) => {
              const image = new Image();
              image.src = imageUrl;
            });
        }; 
        preLoadImages();
    },[])

    return (
        <>
            <div className='building-spinner' ref={ref}>
                <Button className='building-spinner--overhead-button' variant='primary' onClick={e => spinBuilding('top')}>NIVELES</Button>
                <div className={`building-spinner--transition ${isPlayable ? '' : 'is-loading'}`}>
                    <span className='building-spinner--transition-loader' style={{ backgroundImage: `url("${imageLoader}")` }}></span>
                    { transition &&
                        <VideoTransition
                            url={transition} 
                            isPlaying={isPlaying} 
                            handleOnReady={() => {
                                setTimeout(() => {
                                    setIsPlayable(true);
                                },1000)
                            }}
                            handleCallback={() => {
                                setIsPlaying(false);
                                ref.current?.classList.remove('is-spinning',);

                                handleAutoplayCallback();
                            } }                    
                        />                        
                    }
                </div>
                
                <div className="building-spinner--controls">
                    <Button variant="primary" onClick={e => spinBuilding('left')}><span className="icon icon--left"></span></Button>
                    <Button variant="primary" onClick={e => spinBuilding('right')}><span className="icon icon--right"></span></Button>
                </div>
            </div>
        </>
    )
}

export default BuildingSpinner