import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap"
import { IArea } from "../../@types/project";
import { ProjectContext } from "../../context/projectContext";
import './InterestedCard.scss';


type Props = {
    area?: IArea,
    show: boolean,
    handleClose: any,
}

const InterestedCard = ({area, show, handleClose} : Props) => {

    const [showModal, setShowModal] = useState(show);
    const context = useContext(ProjectContext);
    const level = context?.project.buildings[0].levels.find((l : any) => l.level === context?.simulator.current_level)
    const onHandleClose = () => { 
        //setShowModal(false);
        handleClose(false);
    };

    useEffect(() => {
        setShowModal(show);
    },[show])

    return (
        <Modal
            className="interested-card"
            show={showModal}
            fullscreen={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onHandleClose}
            style={{zIndex: 3000}}
        >
          <Modal.Body>
            <Container fluid style={{ minHeight: '100vh' }}>
                <Row className="d-flex justify-content-between align-items-stretch" style={{ minHeight: '100vh' }}>
                    <Col md='5' className="p-5 bg-secondary h-full d-flex align-items-center justify-content-center">
                        <Button className="btn-close" variant="secondary" onClick={handleClose}></Button>
                        <div className="text-center text-primary">
                            <span className="home--logo" style={{ backgroundImage: `url(${ context?.project.styles.intro.logo })` }}></span>
                            <p className="mt-4">Estoy interesado en</p>
                            <h3 className="my-4 text-primary">
                                {area?.area}
                            </h3>
                            <div>
                                Nivel {level.level}
                            </div>
                            <form className="">
                                <div className="form-group my-2">
                                    <input type="text" className="form-control" placeholder="Nombre" />
                                </div>
                                <div className="form-group my-2">
                                    <input type="text" className="form-control" placeholder="Teléfono" />
                                </div>
                                <div className="form-group my-2">
                                    <input type="text" className="form-control" placeholder="Email" />
                                </div>
                                <div className="actions">
                                    <Button variant="primary" className="text-white">Enviar</Button>
                                </div>
                            </form>
                        </div>
                    </Col>
                </Row>
            </Container>
          </Modal.Body>
        </Modal>
    )
}

export default InterestedCard;