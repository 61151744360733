import { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import './ArrowNavigation.scss';

import project from '../../data/project'
import { ProjectContext } from '../../context/projectContext';

type Props = {
    onChangeLevel: (level:number) => void,
}

const ArrowNavigation = ({onChangeLevel}: Props) => {
    const context = useContext(ProjectContext);
    const levels = project.buildings[0].levels.sort((a,b) => a.level - b.level).reverse();

    const stepLevel = (direction: string) => {
        console.log(direction);
        console.log("context:",context);
        const currentLevel = context?.simulator.current_level || 1;
        const currentLevelIndex = levels.findIndex((l: any) => l.level === currentLevel) || 0;
        let incomingLevel = null;
        // inverted sum beacuse of desc sorting
        if(direction==='up')
            incomingLevel = levels[currentLevelIndex - 1] || levels[0];
        if(direction==='down')
            incomingLevel = levels[currentLevelIndex + 1] || levels[levels.length-1];
        if(incomingLevel){
            onChangeLevel(incomingLevel.level);
        }
    } 

    return(
        <div className="arrow-navigation">
            { context?.simulator.current_level && context?.simulator.current_level < levels[0].level &&
                <Button variant="transparent" className="arrow-navigation--top" onClick={() => stepLevel('up')}><span className="icon icon--top"></span></Button>
            }
            { context?.simulator.current_level && context?.simulator.current_level > 1 &&
                <Button variant="transparent" className="arrow-navigation--down" onClick={() => stepLevel('down')}> <span className="icon icon--down"></span></Button>
            }
        </div>
    )
}

export default ArrowNavigation;