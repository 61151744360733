import { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from "../../context/sessionContext";
import { ProjectContext } from '../../context/projectContext';
import './LookScreen.scss';
import { auth, db } from "../../firebase";
import { addDoc, collection, doc, getDoc, serverTimestamp } from "firebase/firestore";
import { signInWithEmailAndPassword } from "firebase/auth";
import useLogger from "../../hooks/useLogger";

type ErrorMessage = {
    name: String,
    message: String,
}

const LookScreen = () => {
    const navigate = useNavigate();
    const [session, setSession] = useSessionContext();
    const context = useContext(ProjectContext);
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errorMessages, setErrorMessages] = useState<ErrorMessage | null>();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const logger = useLogger()

    const errors = {
        email: "Email o contraseña inválida",
        pass: "Email o contraseña inválida"
    };

    const renderErrorMessage = (name : String) =>
        name === errorMessages?.name && (
            <div className="error mt-2">{errorMessages.message}</div>
        );
    useEffect(() => {
        if(session.user)
            logger.log({
                action: 'LOGIN',
                message: 'Inicio de sesión',
            })
    },[session])
    const handleSubmit = async () => {

        //const userData = context?.project.auth.find((user:any) => user.email===email.value);
        let userData;
        try {
            userData = await signInWithEmailAndPassword(auth,email,password).then( async response => {
                let remoteUser = response.user
                const docRef = doc(db, "users", remoteUser.uid);
                const docSnap = await getDoc(docRef);
                const remoteUserDetails = docSnap.data();
                // Valid login
                setSession({
                    ...session, 
                    user : {
                        uid: remoteUser.uid,
                        email: remoteUser.email,
                        displayName: remoteUser.displayName,
                        role: remoteUserDetails?.role,
                    },
                    isAuthenticated: true,
                });
            });
        }
        catch (error) {
            console.log("error:",error);
            setErrorMessages({ name: "email", message: errors.email });
        }
    }

    useEffect(() => {
        if(session.isAuthenticated){
            //console.warn("useEffect-session.isAuthenticated:",session.isAuthenticated)
            //console.warn("useEffect-session.redirectPath:",session.redirectPath)
            navigate(session.redirectPath);
        }
    },[session])

    return (
        <>
            <Container fluid className="login-page flex align-items-center justify-content-center">
                <Row className="">
                    <Col md='6' className="text-center">
                        <Card style={{ width: '18rem' }}>
                            <Card.Header>
                                <div className="login-page--logo" style={{backgroundImage: `url('${ context?.project.styles.intro.logo }')`}}></div>
                            </Card.Header>
                            <Card.Body>
                                    <Form.Group className="" controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="text" placeholder="Email" name="email" onChange={(e) => setEmail(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="pass">
                                        <Form.Label>Contraseña</Form.Label>
                                        <Form.Control type="password" placeholder="Contraseña" name="pass" onChange={(e) => setPassword(e.target.value)} />
                                    </Form.Group>
                                    <Button variant="primary" className="text-white" onClick={handleSubmit}>
                                        Acceder
                                    </Button>
                                    {renderErrorMessage("email")}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default LookScreen;