import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import './VideoTransition.scss'

type Props = {
    url: string,
    isPlaying: boolean,
    handleOnReady: (params: any) => any,
    handleCallback: (params: any) => any,
}

const VideoTransition = ({url,isPlaying,handleOnReady,handleCallback} : Props) => {

    return (
        <div className={ `view--transition`}>
            <ReactPlayer url={ url } playing={isPlaying} controls={false} muted={true} width='100%' height='100%' onReady={() => {handleOnReady(true)}} onEnded={() => handleCallback(true)} />
        </div>
    )
}
export default VideoTransition;