import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PanelHeader from '../../../pages/AdminPanel/PanelHeader/PanelHeader';

type Props = {
    className?: string,
    style?: React.CSSProperties,
    children: JSX.Element,
    leftbar?: JSX.Element,
    rightbar?: JSX.Element
}
const PanelLayout =({className, style, children, leftbar, rightbar}: Props) => {
    const [extModalShow, setExtModalShow] = useState<boolean>(false);
    const [modalVariant, setModalVariant] = useState<string>('');
    const openModal = (variant: string) => {
        setModalVariant(variant);
        setExtModalShow(true)
    }
    return(
        <Container fluid className={`layout-container px-0 ${className}`}>
            <PanelHeader />
            <main className='mt-5'>
                <Row className='px-0 mx-0'>
                    {
                        leftbar && 
                        <Col className='layout-aside'>
                            { leftbar }
                        </Col>
                    }
                    <Col className='layout-main px-0' style={style}>
                        {children}
                    </Col>
                    {
                        rightbar && 
                        <Col className='layout-aside'>
                            { rightbar }
                        </Col>
                    }
                </Row>
            </main>
        </Container>
    )
}

export default PanelLayout;