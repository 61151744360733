import { Badge, Button, Card, Col, Image, Row, Stack, Table } from "react-bootstrap"
import moment from 'moment'
import 'moment/locale/es'
import UsersForm from "../UsersForm/UsersForm";
import { useState } from "react";
import { UserType } from "../../../@types/user";
moment.locale('es');


type Props = {
    data: any[],
    onHandleRefresh: () => void
}

const UsersTable = ({data, onHandleRefresh} : Props) => {

    const [ selectedUser, setSelectedUser ] = useState<UserType | null>()
    const defaultUser = {
        uid: '',
        displayName: '',
        email: '',
        username: '',
        password: '',
        passwordConfirmation: '',
        role: '',
    }
    const handleDelete = (user : UserType) => {
        console.warn('Delete user...',user)
        handleRefresh()
    }
    const handleRefresh = () => {
        setSelectedUser(null);
        onHandleRefresh()
    }

    return (
        <Card>
            <Card.Header>
                <Stack direction="horizontal" gap={2}>
                    <Col>
                        <h4>Usuarios</h4>
                    </Col>
                    { !selectedUser &&
                        <Col className="ms-auto text-end">
                            <Button onClick={() => setSelectedUser(defaultUser) } variant="dark"><i className="bi bi-plus"></i> Nuevo usuario</Button>
                        </Col>
                    }
                </Stack>
            </Card.Header>
            <Card.Body>
                { !selectedUser && (
                <Table hover variant="light" size="lg" responsive>
                    <thead>
                        <tr>
                            <th><i className="bi bi-person"></i> Nombre</th>
                            <th><i className="bi bi-person"></i> Email</th>
                            <th><i className="bi bi-activity"></i> Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                       { data.map((user, index) => {
                            return (
                            <tr key={index}>
                                <td><Image src={`https://ui-avatars.com/api/?size=48&name=${user.displayName}`} className="me-2" roundedCircle /> { user.displayName } {user.role=='admin' ? <Badge bg="primary" pill>Admin</Badge> : ''}</td>
                                <td>{ user.email }</td>
                                <td>
                                    <Button onClick={() => setSelectedUser(user) } variant="outline-primary" ><i className="bi bi-pencil"></i></Button>
                                    <Button onClick={() => handleDelete(user)} variant="transparent"><i className="bi bi-trash"></i></Button>
                                </td>
                            </tr>);
                        })}
                    </tbody>
                </Table>
                )}
                {selectedUser && 
                    <UsersForm 
                        selectedUser={selectedUser} 
                        onHandleCancel={() => setSelectedUser(null)}
                        onHandleClose={handleRefresh} />
                }
            </Card.Body>
        </Card>
    )
}

export default UsersTable