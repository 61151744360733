import SimpleImageSlider from "react-simple-image-slider";

type Props = {
    images:[],
    className: string,
}

const AreaGallery = ({images, className} : Props) => {

    return (
        <div className={className}>
            <p className="gallery-disclaimer">*imagenes ilustrativas</p>
            <SimpleImageSlider
                width={'100vw'}
                height={'100vh'}
                images={images}
                showBullets={images.length > 1 ? true : false}
                showNavs={images.length > 1 ? true : false}
                style={{
                }}
            />
        </div>
    );
}

export default AreaGallery;