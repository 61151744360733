//console.warn("process.env.NODE_ENV:",process.env.NODE_ENV);
const BASE_URL = process.env.NODE_ENV === 'production' ? "/" : "/";
const PROJECTS_DIR = BASE_URL+"projects/";
const PROJECT_DIR = PROJECTS_DIR+"atiko/";
const project = {
    id: 1,
    name: "Atiko",
    description: "Demo project",
    status: true,
    auth: [
      {
        email: "local@atiko.mx",
        password: "CelebraLoTuyo",
      }
    ],
    styles: {
        colors: {
            primary: "#FF2A000",
        },
        intro : {
            backgroundColor: "rgba(178, 247, 237, 0.75)",
            logo: PROJECT_DIR + "intro-logo.svg",
        }
    },
    assets : {
        masterplan : {
            transitionIn : PROJECT_DIR + "transitions/D-A.mp4", 
        },
        spinner : {
            sides : [
                {   id: 1,
                    endFrame: PROJECT_DIR + 'spinner/A.png',
                    transitions : {
                        left: PROJECT_DIR + 'spinner/A-trans-left.mp4',
                        right: PROJECT_DIR + 'spinner/A-trans-right.mp4',
                        overhead: PROJECT_DIR + 'spinner/A-trans-overhead.mp4'
                    }
                },
                {   id: 2,
                    endFrame: PROJECT_DIR + 'spinner/B.png',
                    transitions : {
                        left: PROJECT_DIR + 'spinner/B-trans-left.mp4',
                        right: PROJECT_DIR + 'spinner/B-trans-right.mp4',
                        overhead: PROJECT_DIR + 'spinner/B-trans-overhead.mp4'
                    }
                },
                {   id: 3,
                    endFrame: PROJECT_DIR + 'spinner/C.png',
                    transitions : {
                        left: PROJECT_DIR + 'spinner/C-trans-left.mp4',
                        right: PROJECT_DIR + 'spinner/C-trans-right.mp4',
                        overhead: PROJECT_DIR + 'spinner/C-trans-overhead.mp4'
                    }
                },
                {   id: 4,
                    endFrame: PROJECT_DIR + 'spinner/D.png',
                    transitions : {
                        left: PROJECT_DIR + 'spinner/D-trans-left.mp4',
                        right: PROJECT_DIR + 'spinner/D-trans-right.mp4',
                        overhead: PROJECT_DIR + 'spinner/D-trans-overhead.mp4'
                    }
                },
            ]
        },
        galleries: [
          {
            code: 'G1', 
            prototypes: ['A1','A2','D1','D2','D3','D4'],
            images: [
              {
                url: PROJECT_DIR + 'galleries/model-A/img-depa-atiko-a-1.jpg',
                original: PROJECT_DIR + 'galleries/model-A/img-depa-atiko-a-1.jpg',
                width: 320,
                height: 174,
              },
            ],
          },
          {
            code: 'G2', 
            prototypes: ['B1','B2','B3','E1','E2'],
            images: [
              {
                url: PROJECT_DIR + 'galleries/model-B/img-depa-atiko-b-1.jpg',
                original: PROJECT_DIR + 'galleries/model-B/img-depa-atiko-b-1.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'galleries/model-B/img-depa-atiko-b-2.jpg',
                original: PROJECT_DIR + 'galleries/model-B/img-depa-atiko-b-2.jpg',
                width: 320,
                height: 174,
              },
            ],
          },
          {
            code: 'G3', 
            prototypes: ['C1','C2','C3'],
            images: [
              {
                url: PROJECT_DIR + 'galleries/model-C/img-depa-atiko-c-1.jpg',
                original: PROJECT_DIR + 'galleries/model-C/img-depa-atiko-c-1.jpg',
                width: 320,
                height: 174,
              },
            ],
          },
        ],
        gallery: [
            {
              url: PROJECT_DIR + 'gallery/img-depa-atiko-1.jpg',
              original: PROJECT_DIR + 'gallery/img-depa-atiko-1.jpg',
              width: 320,
              height: 174,
            },
            {
              url: PROJECT_DIR + 'gallery/img-depa-atiko-2.jpg',
              original: PROJECT_DIR + 'gallery/img-depa-atiko-2.jpg',
              width: 320,
              height: 174,
            },
            {
              url: PROJECT_DIR + 'gallery/img-depa-atiko-3.jpg',
              original: PROJECT_DIR + 'gallery/img-depa-atiko-3.jpg',
              width: 320,
              height: 174,
            },
            {
              url: PROJECT_DIR + 'gallery/img-depa-atiko-4.jpg',
              original: PROJECT_DIR + 'gallery/img-depa-atiko-4.jpg',
              width: 320,
              height: 174,
            },
        ],
        views: [
          { name: 'macroplaza', prototypes: ['C1','C2','C3','E1','E2'], asset: PROJECT_DIR + 'views/macroplaza.jpg' },
          { name: 'santalucia', prototypes: ['A1','A2','B1','B2','B3','D1','D2','D3','D4'], asset: PROJECT_DIR + 'views/santalucia.jpg' },
        ]
    },
    buildings : [
            {
                "id": 1,
                "name": "Torre 1",
                "coords": [714,353, 600,384, 485,416, 486,431, 468,435, 445,427, 444,427, 403,355, 403,322, 399,317, 399,241, 399,146, 472,135, 546,125, 613,115, 650,146, 657,152, 657,160, 666,158, 714,174, 714,316, 714,316, 714,353],
                "shape": "poly",
                "active": 1,
                "stats": {
                    "free": 7
                },
                "levels": [
                  {
                    "id": 1,
                    "building_id": 1,
                    "level": 1,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 101,
                        "area": "LOCALES COMERCIALES",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1597,897, 1588,917, 1481,916, 1375,915, 1269,914, 1126,913, 984,912, 984,911, 892,910, 789,910, 687,910, 584,910, 608,805, 572,805, 591,718, 610,632, 732,632, 854,632, 977,632, 1099,632, 1221,632, 1206,535, 1146,535, 1133,420, 1266,420, 1399,420, 1444,527, 1482,618, 1520,708, 1559,803, 1597,897
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "FL-1",
                        "type": {
                          "id": 1,
                          "shortCode": "FL-1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null,
                          "gallery": [
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_1-a_atiko.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_1-a_atiko.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_1-b_atiko.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_1-b_atiko.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_1-c_atiko.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_1-c_atiko.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_2-a_atiko.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_2-a_atiko.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_2-b_atiko.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_2-b_atiko.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_2-c_atiko.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_2-c_atiko.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_calle_atiko.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-1/img_ext_calle_atiko.jpg',
                              width: 320,
                              height: 174,
                            },
                          ]
                        }
                      },
                      {
                        "id": 102,
                        "area": "AMENIDADES",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          765,227,780,127,768,95,655,80,550,75,522,47,465,183,490,225,458,290,748,292
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "FL-1",
                        "type": {
                          "id": 1,
                          "shortCode": "FL-1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null,
                          "gallery": [
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-1-amenidades/amenidades-1.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-1-amenidades/amenidades-1.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-1-amenidades/amenidades-2.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-1-amenidades/amenidades-2.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-1-amenidades/amenidades-3.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-1-amenidades/amenidades-3.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-1-amenidades/amenidades-4.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-1-amenidades/amenidades-4.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-1-amenidades/amenidades-5.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-1-amenidades/amenidades-5.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-1-amenidades/amenidades-6.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-1-amenidades/amenidades-6.jpg',
                              width: 320,
                              height: 174,
                            },
                          ]
                        }
                      }
                    ]
                  },
                  {
                    "id": 8,
                    "building_id": 1,
                    "level": 8,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 801,
                        "area": "801",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          689,
                          647,
                          673,
                          737,
                          656,
                          828,
                          529,
                          828,
                          550,
                          737,
                          571,
                          647,
                          689,
                          647
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 802,
                        "area": "802",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          802,
                          647,
                          796,
                          727,
                          790,
                          807,
                          784,
                          807,
                          783,
                          828,
                          656,
                          828,
                          673,
                          737,
                          689,
                          647,
                          802,
                          647
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 803,
                        "area": "803",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1019,
                          809,
                          1013,
                          809,
                          902,
                          809,
                          790,
                          809,
                          796,
                          728,
                          802,
                          647,
                          909,
                          647,
                          1015,
                          647,
                          1017,
                          728,
                          1019,
                          809
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 804,
                        "area": "804",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1248,
                          808,
                          1241,
                          808,
                          1130,
                          808,
                          1019,
                          809,
                          1017,
                          728,
                          1015,
                          647,
                          1121,
                          647,
                          1227,
                          647,
                          1237,
                          728,
                          1248,
                          808
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 805,
                        "area": "805",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1486,
                          808,
                          1367,
                          808,
                          1248,
                          808,
                          1237,
                          728,
                          1227,
                          647,
                          1333,
                          647,
                          1439,
                          647,
                          1462,
                          728,
                          1486,
                          808
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 806,
                        "area": "806",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          794,
                          614,
                          798,
                          626,
                          696,
                          626,
                          593,
                          626,
                          575,
                          613,
                          597,
                          514,
                          659,
                          514,
                          659,
                          508,
                          664,
                          508,
                          669,
                          514,
                          669,
                          508,
                          729,
                          508,
                          736,
                          520,
                          769,
                          520,
                          769,
                          514,
                          800,
                          514,
                          794,
                          605,
                          794,
                          614
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 807,
                        "area": "807",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1006,
                          614,
                          1006,
                          626,
                          902,
                          626,
                          798,
                          626,
                          794,
                          614,
                          794,
                          605,
                          800,
                          514,
                          824,
                          514,
                          827,
                          517,
                          849,
                          517,
                          849,
                          515,
                          866,
                          515,
                          866,
                          506,
                          925,
                          506,
                          925,
                          519,
                          966,
                          519,
                          966,
                          514,
                          996,
                          514,
                          996,
                          509,
                          1004,
                          509,
                          1005,
                          605,
                          1006,
                          614
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 808,
                        "area": "808",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1431,
                          614,
                          1409,
                          626,
                          1329,
                          626,
                          1250,
                          625,
                          1248,
                          617,
                          1242,
                          617,
                          1234,
                          626,
                          1206,
                          627,
                          1213,
                          613,
                          1212,
                          605,
                          1200,
                          508,
                          1207,
                          508,
                          1207,
                          511,
                          1237,
                          511,
                          1237,
                          516,
                          1265,
                          516,
                          1278,
                          504,
                          1336,
                          504,
                          1337,
                          511,
                          1408,
                          510,
                          1431,
                          614
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      /*{
                        "id": 809,
                        "area": "L01",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1133,
                          420,
                          1266,
                          420,
                          1399,
                          420,
                          1444,
                          527,
                          1327,
                          527,
                          1210,
                          527,
                          1206,
                          535,
                          1146,
                          535,
                          1133,
                          420
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 810,
                        "area": "L02",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1444,
                          527,
                          1482,
                          618,
                          1520,
                          708,
                          1422,
                          708,
                          1324,
                          708,
                          1226,
                          708,
                          1214,
                          641,
                          1223,
                          640,
                          1206,
                          535,
                          1210,
                          527,
                          1327,
                          527,
                          1444,
                          527
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 812,
                        "area": "L04",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          903,
                          632,
                          898,
                          771,
                          892,
                          910,
                          1021,
                          910,
                          1018,
                          831,
                          1001,
                          831,
                          1001,
                          777,
                          1015,
                          777,
                          1013,
                          705,
                          1011,
                          632,
                          903,
                          632
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 811,
                        "area": "L04",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1520,
                          708,
                          1559,
                          803,
                          1597,
                          897,
                          1588,
                          917,
                          1479,
                          916,
                          1369,
                          915,
                          1260,
                          914,
                          1243,
                          811,
                          1226,
                          708,
                          1324,
                          708,
                          1422,
                          708,
                          1520,
                          708
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 813,
                        "area": "L05",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          784,
                          632,
                          770,
                          771,
                          756,
                          910,
                          892,
                          910,
                          898,
                          771,
                          903,
                          632,
                          784,
                          632
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 814,
                        "area": "L06",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          610,
                          632,
                          697,
                          632,
                          784,
                          632,
                          770,
                          771,
                          756,
                          910,
                          670,
                          910,
                          584,
                          910,
                          608,
                          805,
                          572,
                          805,
                          591,
                          718,
                          610,
                          632
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }*/
                    ]
                  },
                  {
                    "id": 9,
                    "building_id": 1,
                    "level": 9,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 901,
                        "area": "901",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          686,
                          643,
                          673,
                          733,
                          659,
                          824,
                          536,
                          824,
                          555,
                          733,
                          573,
                          643,
                          686,
                          643
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 902,
                        "area": "902",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          802,
                          643,
                          796,
                          723,
                          790,
                          803,
                          784,
                          803,
                          783,
                          824,
                          659,
                          824,
                          673,
                          733,
                          686,
                          643,
                          802,
                          643
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 903,
                        "area": "903",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1019,
                          805,
                          1013,
                          805,
                          902,
                          805,
                          790,
                          805,
                          796,
                          724,
                          802,
                          643,
                          909,
                          643,
                          1015,
                          643,
                          1017,
                          724,
                          1019,
                          805
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 904,
                        "area": "904",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1248,
                          804,
                          1241,
                          804,
                          1130,
                          804,
                          1019,
                          805,
                          1017,
                          724,
                          1015,
                          643,
                          1121,
                          643,
                          1227,
                          643,
                          1237,
                          724,
                          1248,
                          804
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 905,
                        "area": "905",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1480,
                          804,
                          1364,
                          804,
                          1248,
                          804,
                          1237,
                          724,
                          1227,
                          643,
                          1333,
                          643,
                          1439,
                          643,
                          1459,
                          724,
                          1480,
                          804
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 906,
                        "area": "906",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          794,
                          610,
                          798,
                          622,
                          696,
                          622,
                          593,
                          622,
                          578,
                          611,
                          597,
                          510,
                          659,
                          510,
                          659,
                          504,
                          664,
                          504,
                          669,
                          510,
                          669,
                          504,
                          729,
                          504,
                          736,
                          516,
                          769,
                          516,
                          769,
                          510,
                          800,
                          510,
                          794,
                          601,
                          794,
                          610
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 907,
                        "area": "907",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1006,
                          610,
                          1006,
                          622,
                          902,
                          622,
                          798,
                          622,
                          794,
                          610,
                          794,
                          601,
                          800,
                          510,
                          824,
                          510,
                          827,
                          513,
                          849,
                          513,
                          849,
                          511,
                          866,
                          511,
                          866,
                          502,
                          925,
                          502,
                          925,
                          515,
                          966,
                          515,
                          966,
                          510,
                          996,
                          510,
                          996,
                          505,
                          1004,
                          505,
                          1005,
                          601,
                          1006,
                          610
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 908,
                        "area": "908",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1431,
                          610,
                          1409,
                          622,
                          1329,
                          622,
                          1250,
                          621,
                          1248,
                          613,
                          1242,
                          613,
                          1234,
                          622,
                          1206,
                          623,
                          1213,
                          609,
                          1212,
                          601,
                          1200,
                          504,
                          1207,
                          504,
                          1207,
                          507,
                          1237,
                          507,
                          1237,
                          512,
                          1265,
                          512,
                          1278,
                          500,
                          1336,
                          500,
                          1337,
                          507,
                          1408,
                          506,
                          1431,
                          610
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      /*{
                        "id": 909,
                        "area": "L01",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1133,
                          420,
                          1266,
                          420,
                          1399,
                          420,
                          1444,
                          527,
                          1327,
                          527,
                          1210,
                          527,
                          1206,
                          535,
                          1146,
                          535,
                          1133,
                          420
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 910,
                        "area": "L02",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1444,
                          527,
                          1482,
                          618,
                          1520,
                          708,
                          1422,
                          708,
                          1324,
                          708,
                          1226,
                          708,
                          1214,
                          641,
                          1223,
                          640,
                          1206,
                          535,
                          1210,
                          527,
                          1327,
                          527,
                          1444,
                          527
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 912,
                        "area": "L04",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          903,
                          632,
                          898,
                          771,
                          892,
                          910,
                          1021,
                          910,
                          1018,
                          831,
                          1001,
                          831,
                          1001,
                          777,
                          1015,
                          777,
                          1013,
                          705,
                          1011,
                          632,
                          903,
                          632
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 911,
                        "area": "L04",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1520,
                          708,
                          1559,
                          803,
                          1597,
                          897,
                          1588,
                          917,
                          1479,
                          916,
                          1369,
                          915,
                          1260,
                          914,
                          1243,
                          811,
                          1226,
                          708,
                          1324,
                          708,
                          1422,
                          708,
                          1520,
                          708
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 913,
                        "area": "L05",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          784,
                          632,
                          770,
                          771,
                          756,
                          910,
                          892,
                          910,
                          898,
                          771,
                          903,
                          632,
                          784,
                          632
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 914,
                        "area": "L06",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          610,
                          632,
                          697,
                          632,
                          784,
                          632,
                          770,
                          771,
                          756,
                          910,
                          670,
                          910,
                          584,
                          910,
                          608,
                          805,
                          572,
                          805,
                          591,
                          718,
                          610,
                          632
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }*/
                    ]
                  },
                  {
                    "id": 10,
                    "building_id": 1,
                    "level": 10,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 1001,
                        "area": "1001",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          686,
                          639,
                          673,
                          729,
                          660,
                          820,
                          536,
                          820,
                          555,
                          729,
                          573,
                          639,
                          686,
                          639
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1002,
                        "area": "1002",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          802,
                          639,
                          796,
                          719,
                          790,
                          799,
                          784,
                          799,
                          783,
                          820,
                          660,
                          820,
                          673,
                          729,
                          686,
                          639,
                          802,
                          639
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A2",
                        "type": {
                          "id": 2,
                          "shortCode": "A2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1003,
                        "area": "1003",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1019,
                          801,
                          1013,
                          801,
                          902,
                          801,
                          790,
                          801,
                          796,
                          720,
                          802,
                          639,
                          909,
                          639,
                          1015,
                          639,
                          1017,
                          720,
                          1019,
                          801
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B1",
                        "type": {
                          "id": 3,
                          "shortCode": "B1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1004,
                        "area": "1004",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1248,
                          800,
                          1241,
                          800,
                          1130,
                          800,
                          1019,
                          801,
                          1017,
                          720,
                          1015,
                          639,
                          1121,
                          639,
                          1227,
                          639,
                          1237,
                          720,
                          1248,
                          800
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B2",
                        "type": {
                          "id": 4,
                          "shortCode": "B2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1005,
                        "area": "1005",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1480,
                          800,
                          1364,
                          800,
                          1248,
                          800,
                          1237,
                          720,
                          1227,
                          639,
                          1333,
                          639,
                          1439,
                          639,
                          1459,
                          720,
                          1480,
                          800
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B3",
                        "type": {
                          "id": 5,
                          "shortCode": "B3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1006,
                        "area": "1006",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          794,
                          606,
                          798,
                          618,
                          696,
                          618,
                          593,
                          618,
                          578,
                          607,
                          597,
                          506,
                          659,
                          506,
                          659,
                          500,
                          664,
                          500,
                          669,
                          506,
                          669,
                          500,
                          729,
                          500,
                          736,
                          512,
                          769,
                          512,
                          769,
                          506,
                          800,
                          506,
                          794,
                          597,
                          794,
                          606
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C1",
                        "type": {
                          "id": 6,
                          "shortCode": "C1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1007,
                        "area": "1007",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1006,
                          606,
                          1006,
                          618,
                          902,
                          618,
                          798,
                          618,
                          794,
                          606,
                          794,
                          597,
                          800,
                          506,
                          824,
                          506,
                          827,
                          509,
                          849,
                          509,
                          849,
                          507,
                          866,
                          507,
                          866,
                          498,
                          925,
                          498,
                          925,
                          511,
                          966,
                          511,
                          966,
                          506,
                          996,
                          506,
                          996,
                          501,
                          1004,
                          501,
                          1005,
                          597,
                          1006,
                          606
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C2",
                        "type": {
                          "id": 7,
                          "shortCode": "C2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1008,
                        "area": "1008",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1431,
                          606,
                          1409,
                          618,
                          1329,
                          618,
                          1250,
                          617,
                          1248,
                          609,
                          1242,
                          609,
                          1234,
                          618,
                          1206,
                          619,
                          1213,
                          605,
                          1212,
                          597,
                          1200,
                          500,
                          1207,
                          500,
                          1207,
                          503,
                          1237,
                          503,
                          1237,
                          508,
                          1265,
                          508,
                          1278,
                          496,
                          1336,
                          496,
                          1337,
                          503,
                          1408,
                          502,
                          1431,
                          606
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C3",
                        "type": {
                          "id": 8,
                          "shortCode": "C3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 11,
                    "building_id": 1,
                    "level": 11,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 1101,
                        "area": "1101",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          686,
                          636,
                          673,
                          726,
                          660,
                          817,
                          536,
                          817,
                          555,
                          726,
                          573,
                          636,
                          686,
                          636
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1102,
                        "area": "1102",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          802,
                          636,
                          796,
                          716,
                          790,
                          796,
                          784,
                          796,
                          783,
                          817,
                          660,
                          817,
                          673,
                          726,
                          686,
                          636,
                          802,
                          636
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A2",
                        "type": {
                          "id": 2,
                          "shortCode": "A2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1103,
                        "area": "1103",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1019,
                          798,
                          1013,
                          798,
                          902,
                          798,
                          790,
                          798,
                          796,
                          717,
                          802,
                          636,
                          909,
                          636,
                          1015,
                          636,
                          1017,
                          717,
                          1019,
                          798
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B1",
                        "type": {
                          "id": 3,
                          "shortCode": "B1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1104,
                        "area": "1104",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1248,
                          797,
                          1241,
                          797,
                          1130,
                          797,
                          1019,
                          798,
                          1017,
                          717,
                          1015,
                          636,
                          1121,
                          636,
                          1227,
                          636,
                          1237,
                          717,
                          1248,
                          797
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B2",
                        "type": {
                          "id": 4,
                          "shortCode": "B2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1105,
                        "area": "1105",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1480,
                          797,
                          1364,
                          797,
                          1248,
                          797,
                          1237,
                          717,
                          1227,
                          636,
                          1333,
                          636,
                          1439,
                          636,
                          1459,
                          717,
                          1480,
                          797
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B3",
                        "type": {
                          "id": 5,
                          "shortCode": "B3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1106,
                        "area": "1106",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          794,
                          603,
                          798,
                          615,
                          696,
                          615,
                          593,
                          615,
                          578,
                          604,
                          597,
                          503,
                          659,
                          503,
                          659,
                          497,
                          664,
                          497,
                          669,
                          503,
                          669,
                          497,
                          729,
                          497,
                          736,
                          509,
                          769,
                          509,
                          769,
                          503,
                          800,
                          503,
                          794,
                          594,
                          794,
                          603
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C1",
                        "type": {
                          "id": 6,
                          "shortCode": "C1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1107,
                        "area": "1107",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1006,
                          603,
                          1006,
                          615,
                          902,
                          615,
                          798,
                          615,
                          794,
                          603,
                          794,
                          594,
                          800,
                          503,
                          824,
                          503,
                          827,
                          506,
                          849,
                          506,
                          849,
                          504,
                          866,
                          504,
                          866,
                          495,
                          925,
                          495,
                          925,
                          508,
                          966,
                          508,
                          966,
                          503,
                          996,
                          503,
                          996,
                          498,
                          1004,
                          498,
                          1005,
                          594,
                          1006,
                          603
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C2",
                        "type": {
                          "id": 7,
                          "shortCode": "C2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1108,
                        "area": "1108",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1431,
                          603,
                          1409,
                          615,
                          1329,
                          615,
                          1250,
                          614,
                          1248,
                          606,
                          1242,
                          606,
                          1234,
                          615,
                          1206,
                          616,
                          1213,
                          602,
                          1212,
                          594,
                          1200,
                          497,
                          1207,
                          497,
                          1207,
                          500,
                          1237,
                          500,
                          1237,
                          505,
                          1265,
                          505,
                          1278,
                          493,
                          1336,
                          493,
                          1337,
                          500,
                          1408,
                          499,
                          1431,
                          603
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C3",
                        "type": {
                          "id": 8,
                          "shortCode": "C3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 12,
                    "building_id": 1,
                    "level": 12,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 1201,
                        "area": "1201",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          686,
                          631,
                          675,
                          721,
                          663,
                          812,
                          536,
                          812,
                          555,
                          721,
                          573,
                          631,
                          686,
                          631
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1202,
                        "area": "1202",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          802,
                          631,
                          796,
                          711,
                          790,
                          791,
                          784,
                          791,
                          783,
                          812,
                          663,
                          812,
                          675,
                          721,
                          686,
                          631,
                          802,
                          631
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A2",
                        "type": {
                          "id": 2,
                          "shortCode": "A2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1203,
                        "area": "1203",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1019,
                          793,
                          1013,
                          793,
                          902,
                          793,
                          790,
                          793,
                          796,
                          712,
                          802,
                          631,
                          909,
                          631,
                          1015,
                          631,
                          1017,
                          712,
                          1019,
                          793
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B1",
                        "type": {
                          "id": 3,
                          "shortCode": "B1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1204,
                        "area": "1204",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1248,
                          792,
                          1241,
                          792,
                          1130,
                          792,
                          1019,
                          793,
                          1017,
                          712,
                          1015,
                          631,
                          1121,
                          631,
                          1227,
                          631,
                          1237,
                          712,
                          1248,
                          792
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B2",
                        "type": {
                          "id": 4,
                          "shortCode": "B2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1205,
                        "area": "1205",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1480,
                          792,
                          1364,
                          792,
                          1248,
                          792,
                          1237,
                          712,
                          1227,
                          631,
                          1333,
                          631,
                          1439,
                          631,
                          1459,
                          712,
                          1480,
                          792
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B3",
                        "type": {
                          "id": 5,
                          "shortCode": "B3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1206,
                        "area": "1206",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          794,
                          598,
                          798,
                          610,
                          696,
                          610,
                          593,
                          610,
                          578,
                          599,
                          597,
                          498,
                          659,
                          498,
                          659,
                          492,
                          664,
                          492,
                          669,
                          498,
                          669,
                          492,
                          729,
                          492,
                          736,
                          504,
                          769,
                          504,
                          769,
                          498,
                          800,
                          498,
                          794,
                          589,
                          794,
                          598
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C1",
                        "type": {
                          "id": 6,
                          "shortCode": "C1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1207,
                        "area": "1207",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1006,
                          598,
                          1006,
                          610,
                          902,
                          610,
                          798,
                          610,
                          794,
                          598,
                          794,
                          589,
                          800,
                          498,
                          824,
                          498,
                          827,
                          501,
                          849,
                          501,
                          849,
                          499,
                          866,
                          499,
                          866,
                          490,
                          925,
                          490,
                          925,
                          503,
                          966,
                          503,
                          966,
                          498,
                          996,
                          498,
                          996,
                          493,
                          1004,
                          493,
                          1005,
                          589,
                          1006,
                          598
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C2",
                        "type": {
                          "id": 7,
                          "shortCode": "C2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1208,
                        "area": "1208",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1431,
                          598,
                          1409,
                          610,
                          1329,
                          610,
                          1250,
                          609,
                          1248,
                          601,
                          1242,
                          601,
                          1234,
                          610,
                          1206,
                          611,
                          1213,
                          597,
                          1212,
                          589,
                          1200,
                          492,
                          1207,
                          492,
                          1207,
                          495,
                          1237,
                          495,
                          1237,
                          500,
                          1265,
                          500,
                          1278,
                          488,
                          1336,
                          488,
                          1337,
                          495,
                          1408,
                          494,
                          1431,
                          598
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C3",
                        "type": {
                          "id": 8,
                          "shortCode": "C3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 14,
                    "building_id": 1,
                    "level": 14,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 1401,
                        "area": "1201",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          686,
                          631,
                          675,
                          721,
                          663,
                          812,
                          536,
                          812,
                          555,
                          721,
                          573,
                          631,
                          686,
                          631
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1402,
                        "area": "1202",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          802,
                          631,
                          796,
                          711,
                          790,
                          791,
                          784,
                          791,
                          783,
                          812,
                          663,
                          812,
                          675,
                          721,
                          686,
                          631,
                          802,
                          631
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A2",
                        "type": {
                          "id": 2,
                          "shortCode": "A2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1403,
                        "area": "1203",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1019,
                          793,
                          1013,
                          793,
                          902,
                          793,
                          790,
                          793,
                          796,
                          712,
                          802,
                          631,
                          909,
                          631,
                          1015,
                          631,
                          1017,
                          712,
                          1019,
                          793
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B1",
                        "type": {
                          "id": 3,
                          "shortCode": "B1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1404,
                        "area": "1204",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1248,
                          792,
                          1241,
                          792,
                          1130,
                          792,
                          1019,
                          793,
                          1017,
                          712,
                          1015,
                          631,
                          1121,
                          631,
                          1227,
                          631,
                          1237,
                          712,
                          1248,
                          792
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B2",
                        "type": {
                          "id": 4,
                          "shortCode": "B2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1405,
                        "area": "1205",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1480,
                          792,
                          1364,
                          792,
                          1248,
                          792,
                          1237,
                          712,
                          1227,
                          631,
                          1333,
                          631,
                          1439,
                          631,
                          1459,
                          712,
                          1480,
                          792
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B3",
                        "type": {
                          "id": 5,
                          "shortCode": "B3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1406,
                        "area": "1206",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          794,
                          598,
                          798,
                          610,
                          696,
                          610,
                          593,
                          610,
                          578,
                          599,
                          597,
                          498,
                          659,
                          498,
                          659,
                          492,
                          664,
                          492,
                          669,
                          498,
                          669,
                          492,
                          729,
                          492,
                          736,
                          504,
                          769,
                          504,
                          769,
                          498,
                          800,
                          498,
                          794,
                          589,
                          794,
                          598
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C1",
                        "type": {
                          "id": 6,
                          "shortCode": "C1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1407,
                        "area": "1207",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1006,
                          598,
                          1006,
                          610,
                          902,
                          610,
                          798,
                          610,
                          794,
                          598,
                          794,
                          589,
                          800,
                          498,
                          824,
                          498,
                          827,
                          501,
                          849,
                          501,
                          849,
                          499,
                          866,
                          499,
                          866,
                          490,
                          925,
                          490,
                          925,
                          503,
                          966,
                          503,
                          966,
                          498,
                          996,
                          498,
                          996,
                          493,
                          1004,
                          493,
                          1005,
                          589,
                          1006,
                          598
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C2",
                        "type": {
                          "id": 7,
                          "shortCode": "C2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1408,
                        "area": "1208",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1431,
                          598,
                          1409,
                          610,
                          1329,
                          610,
                          1250,
                          609,
                          1248,
                          601,
                          1242,
                          601,
                          1234,
                          610,
                          1206,
                          611,
                          1213,
                          597,
                          1212,
                          589,
                          1200,
                          492,
                          1207,
                          492,
                          1207,
                          495,
                          1237,
                          495,
                          1237,
                          500,
                          1265,
                          500,
                          1278,
                          488,
                          1336,
                          488,
                          1337,
                          495,
                          1408,
                          494,
                          1431,
                          598
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C3",
                        "type": {
                          "id": 8,
                          "shortCode": "C3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 15,
                    "building_id": 1,
                    "level": 15,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 1501,
                        "area": "1501",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          688,
                          624,
                          676,
                          714,
                          665,
                          805,
                          535,
                          805,
                          554,
                          714,
                          572,
                          624,
                          688,
                          624
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1502,
                        "area": "1502",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          801,
                          624,
                          795,
                          704,
                          789,
                          784,
                          783,
                          784,
                          782,
                          805,
                          665,
                          805,
                          676,
                          714,
                          688,
                          624,
                          801,
                          624
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A2",
                        "type": {
                          "id": 2,
                          "shortCode": "A2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1503,
                        "area": "1503",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1018,
                          787,
                          1012,
                          787,
                          901,
                          787,
                          789,
                          787,
                          795,
                          706,
                          801,
                          624,
                          908,
                          624,
                          1014,
                          624,
                          1016,
                          706,
                          1018,
                          787
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B1",
                        "type": {
                          "id": 3,
                          "shortCode": "B1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1504,
                        "area": "1504",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1247,
                          787,
                          1240,
                          787,
                          1129,
                          787,
                          1018,
                          787,
                          1016,
                          706,
                          1014,
                          624,
                          1120,
                          624,
                          1226,
                          624,
                          1236,
                          705,
                          1247,
                          787
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B2",
                        "type": {
                          "id": 4,
                          "shortCode": "B2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1505,
                        "area": "1505",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1479,
                          787,
                          1363,
                          787,
                          1247,
                          787,
                          1236,
                          705,
                          1226,
                          624,
                          1332,
                          624,
                          1438,
                          624,
                          1458,
                          705,
                          1479,
                          787
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B3",
                        "type": {
                          "id": 5,
                          "shortCode": "B3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1506,
                        "area": "1506",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          793,
                          593,
                          797,
                          606,
                          695,
                          606,
                          592,
                          606,
                          577,
                          594,
                          596,
                          491,
                          658,
                          491,
                          658,
                          485,
                          663,
                          485,
                          668,
                          491,
                          668,
                          483,
                          728,
                          483,
                          737,
                          497,
                          768,
                          497,
                          768,
                          492,
                          799,
                          492,
                          793,
                          584,
                          793,
                          593
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C1",
                        "type": {
                          "id": 6,
                          "shortCode": "C1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1507,
                        "area": "1507",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1005,
                          593,
                          1005,
                          606,
                          901,
                          606,
                          797,
                          606,
                          793,
                          593,
                          793,
                          584,
                          799,
                          492,
                          823,
                          492,
                          826,
                          495,
                          848,
                          495,
                          848,
                          492,
                          865,
                          492,
                          865,
                          483,
                          924,
                          483,
                          924,
                          496,
                          965,
                          496,
                          965,
                          491,
                          995,
                          491,
                          995,
                          486,
                          1003,
                          486,
                          1004,
                          584,
                          1005,
                          593
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C2",
                        "type": {
                          "id": 7,
                          "shortCode": "C2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1508,
                        "area": "1508",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1430,
                          593,
                          1408,
                          606,
                          1328,
                          605,
                          1249,
                          605,
                          1247,
                          596,
                          1241,
                          596,
                          1233,
                          605,
                          1205,
                          606,
                          1212,
                          592,
                          1211,
                          584,
                          1199,
                          485,
                          1206,
                          485,
                          1206,
                          488,
                          1236,
                          488,
                          1236,
                          494,
                          1264,
                          494,
                          1277,
                          481,
                          1335,
                          481,
                          1336,
                          489,
                          1407,
                          487,
                          1430,
                          593
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C3",
                        "type": {
                          "id": 8,
                          "shortCode": "C3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 16,
                    "building_id": 1,
                    "level": 16,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 1601,
                        "area": "1601",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          688,
                          624,
                          675,
                          714,
                          663,
                          805,
                          535,
                          805,
                          554,
                          714,
                          572,
                          624,
                          688,
                          624
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1602,
                        "area": "1602",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          801,
                          624,
                          795,
                          704,
                          789,
                          784,
                          783,
                          784,
                          782,
                          805,
                          663,
                          805,
                          675,
                          714,
                          688,
                          624,
                          801,
                          624
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A2",
                        "type": {
                          "id": 2,
                          "shortCode": "A2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1603,
                        "area": "1603",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1018,
                          783,
                          1012,
                          783,
                          901,
                          784,
                          789,
                          784,
                          795,
                          704,
                          801,
                          624,
                          908,
                          624,
                          1014,
                          624,
                          1016,
                          704,
                          1018,
                          783
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B1",
                        "type": {
                          "id": 3,
                          "shortCode": "B1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1604,
                        "area": "1604",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1247,
                          783,
                          1240,
                          783,
                          1129,
                          783,
                          1018,
                          783,
                          1016,
                          704,
                          1014,
                          624,
                          1120,
                          624,
                          1226,
                          624,
                          1236,
                          704,
                          1247,
                          783
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B2",
                        "type": {
                          "id": 4,
                          "shortCode": "B2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1605,
                        "area": "1605",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1479,
                          783,
                          1363,
                          783,
                          1247,
                          783,
                          1236,
                          704,
                          1226,
                          624,
                          1332,
                          624,
                          1438,
                          624,
                          1458,
                          704,
                          1479,
                          783
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B3",
                        "type": {
                          "id": 5,
                          "shortCode": "B3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1606,
                        "area": "1606",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          793,
                          591,
                          797,
                          603,
                          695,
                          603,
                          592,
                          603,
                          577,
                          592,
                          596,
                          491,
                          658,
                          491,
                          658,
                          485,
                          663,
                          485,
                          668,
                          491,
                          668,
                          483,
                          728,
                          483,
                          737,
                          497,
                          768,
                          497,
                          768,
                          491,
                          799,
                          491,
                          793,
                          582,
                          793,
                          591
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C1",
                        "type": {
                          "id": 6,
                          "shortCode": "C1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1607,
                        "area": "1607",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1005,
                          591,
                          1005,
                          603,
                          901,
                          603,
                          797,
                          603,
                          793,
                          591,
                          793,
                          582,
                          799,
                          491,
                          823,
                          491,
                          826,
                          494,
                          848,
                          494,
                          848,
                          492,
                          865,
                          492,
                          865,
                          483,
                          924,
                          483,
                          924,
                          496,
                          965,
                          496,
                          965,
                          491,
                          995,
                          491,
                          995,
                          486,
                          1003,
                          486,
                          1004,
                          582,
                          1005,
                          591
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C2",
                        "type": {
                          "id": 7,
                          "shortCode": "C2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1608,
                        "area": "1608",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1430,
                          591,
                          1408,
                          603,
                          1328,
                          603,
                          1249,
                          602,
                          1247,
                          594,
                          1241,
                          594,
                          1233,
                          603,
                          1205,
                          604,
                          1212,
                          590,
                          1211,
                          582,
                          1199,
                          485,
                          1206,
                          485,
                          1206,
                          488,
                          1236,
                          488,
                          1236,
                          493,
                          1264,
                          493,
                          1277,
                          481,
                          1335,
                          481,
                          1336,
                          488,
                          1407,
                          487,
                          1430,
                          591
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C3",
                        "type": {
                          "id": 8,
                          "shortCode": "C3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 17,
                    "building_id": 1,
                    "level": 17,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 1701,
                        "area": "1701",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          688,
                          623,
                          669,
                          751,
                          665,
                          783,
                          662,
                          800,
                          536,
                          800,
                          552,
                          711,
                          569,
                          623,
                          688,
                          623
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1702,
                        "area": "1702",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          801,
                          623,
                          795,
                          703,
                          790,
                          783,
                          779,
                          782,
                          779,
                          800,
                          662,
                          800,
                          675,
                          711,
                          688,
                          623,
                          801,
                          623
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A2",
                        "type": {
                          "id": 2,
                          "shortCode": "A2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1703,
                        "area": "1703",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1018,
                          782,
                          1012,
                          782,
                          901,
                          783,
                          790,
                          783,
                          795,
                          703,
                          801,
                          623,
                          908,
                          623,
                          1014,
                          623,
                          1016,
                          703,
                          1018,
                          782
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B1",
                        "type": {
                          "id": 3,
                          "shortCode": "B1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1704,
                        "area": "1704",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1250,
                          782,
                          1240,
                          782,
                          1129,
                          782,
                          1018,
                          782,
                          1016,
                          703,
                          1014,
                          623,
                          1121,
                          623,
                          1227,
                          623,
                          1239,
                          703,
                          1250,
                          782
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B2",
                        "type": {
                          "id": 4,
                          "shortCode": "B2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1705,
                        "area": "1705",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1479,
                          782,
                          1364,
                          782,
                          1250,
                          782,
                          1239,
                          703,
                          1227,
                          623,
                          1332,
                          623,
                          1438,
                          623,
                          1458,
                          703,
                          1479,
                          782
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B3",
                        "type": {
                          "id": 5,
                          "shortCode": "B3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1706,
                        "area": "1706",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          793,
                          589,
                          797,
                          601,
                          695,
                          601,
                          592,
                          601,
                          577,
                          590,
                          596,
                          489,
                          658,
                          489,
                          658,
                          483,
                          663,
                          483,
                          668,
                          489,
                          668,
                          481,
                          728,
                          481,
                          737,
                          495,
                          768,
                          495,
                          768,
                          489,
                          799,
                          489,
                          793,
                          580,
                          793,
                          589
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C1",
                        "type": {
                          "id": 6,
                          "shortCode": "C1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1707,
                        "area": "1707",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1005,
                          589,
                          1005,
                          601,
                          901,
                          601,
                          797,
                          601,
                          793,
                          589,
                          793,
                          580,
                          799,
                          489,
                          823,
                          489,
                          826,
                          492,
                          848,
                          492,
                          848,
                          490,
                          865,
                          490,
                          865,
                          481,
                          924,
                          481,
                          924,
                          494,
                          965,
                          494,
                          965,
                          489,
                          995,
                          489,
                          995,
                          484,
                          1003,
                          484,
                          1004,
                          580,
                          1005,
                          589
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C2",
                        "type": {
                          "id": 7,
                          "shortCode": "C2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1708,
                        "area": "1708",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1430,
                          589,
                          1408,
                          601,
                          1328,
                          601,
                          1249,
                          600,
                          1247,
                          592,
                          1241,
                          592,
                          1231,
                          600,
                          1231,
                          600,
                          1205,
                          602,
                          1212,
                          588,
                          1211,
                          580,
                          1199,
                          483,
                          1206,
                          483,
                          1206,
                          486,
                          1236,
                          486,
                          1236,
                          491,
                          1264,
                          491,
                          1277,
                          479,
                          1335,
                          479,
                          1336,
                          486,
                          1407,
                          485,
                          1430,
                          589
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C3",
                        "type": {
                          "id": 8,
                          "shortCode": "C3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 18,
                    "building_id": 1,
                    "level": 18,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 1801,
                        "area": "1802",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          802,
                          619,
                          796,
                          699,
                          790,
                          779,
                          781,
                          779,
                          779,
                          796,
                          663,
                          796,
                          676,
                          707,
                          688,
                          619,
                          802,
                          619
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1802,
                        "area": "1801",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          688,
                          619,
                          669,
                          756,
                          665,
                          781,
                          663,
                          796,
                          536,
                          796,
                          551,
                          707,
                          566,
                          619,
                          688,
                          619
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1803,
                        "area": "1803",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1018,
                          778,
                          1012,
                          778,
                          901,
                          779,
                          790,
                          779,
                          796,
                          699,
                          802,
                          619,
                          908,
                          619,
                          1014,
                          619,
                          1016,
                          699,
                          1018,
                          778
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B1",
                        "type": {
                          "id": 3,
                          "shortCode": "B1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1804,
                        "area": "1804",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1247,
                          778,
                          1240,
                          778,
                          1129,
                          778,
                          1018,
                          778,
                          1018,
                          778,
                          1016,
                          699,
                          1014,
                          619,
                          1120,
                          619,
                          1225,
                          619,
                          1236,
                          699,
                          1247,
                          778
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B2",
                        "type": {
                          "id": 4,
                          "shortCode": "B2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1805,
                        "area": "1805",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1479,
                          778,
                          1363,
                          778,
                          1247,
                          778,
                          1236,
                          699,
                          1225,
                          619,
                          1332,
                          619,
                          1438,
                          619,
                          1458,
                          699,
                          1479,
                          778
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B3",
                        "type": {
                          "id": 5,
                          "shortCode": "B3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1806,
                        "area": "1806",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          793,
                          585,
                          797,
                          597,
                          695,
                          597,
                          592,
                          597,
                          577,
                          586,
                          596,
                          485,
                          658,
                          485,
                          658,
                          479,
                          663,
                          479,
                          668,
                          485,
                          668,
                          477,
                          728,
                          477,
                          737,
                          491,
                          768,
                          491,
                          768,
                          485,
                          799,
                          485,
                          793,
                          576,
                          793,
                          585
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C1",
                        "type": {
                          "id": 6,
                          "shortCode": "C1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1807,
                        "area": "1807",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1005,
                          585,
                          1005,
                          597,
                          901,
                          597,
                          797,
                          597,
                          793,
                          585,
                          793,
                          576,
                          799,
                          485,
                          823,
                          485,
                          826,
                          488,
                          848,
                          488,
                          848,
                          486,
                          865,
                          486,
                          865,
                          477,
                          924,
                          477,
                          924,
                          490,
                          965,
                          490,
                          965,
                          485,
                          995,
                          485,
                          995,
                          480,
                          1003,
                          480,
                          1004,
                          576,
                          1005,
                          585
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C2",
                        "type": {
                          "id": 7,
                          "shortCode": "C2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1808,
                        "area": "1808",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1430,
                          585,
                          1408,
                          597,
                          1328,
                          597,
                          1249,
                          596,
                          1247,
                          588,
                          1241,
                          588,
                          1231,
                          596,
                          1231,
                          596,
                          1205,
                          598,
                          1212,
                          584,
                          1211,
                          576,
                          1199,
                          479,
                          1206,
                          479,
                          1206,
                          482,
                          1236,
                          482,
                          1236,
                          487,
                          1264,
                          487,
                          1277,
                          475,
                          1335,
                          475,
                          1336,
                          482,
                          1407,
                          481,
                          1430,
                          585
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C3",
                        "type": {
                          "id": 8,
                          "shortCode": "C3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 19,
                    "building_id": 1,
                    "level": 19,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 1901,
                        "area": "1901",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          681,
                          666,
                          679,
                          682,
                          678,
                          686,
                          677,
                          693,
                          676,
                          696,
                          675,
                          708,
                          674,
                          712,
                          673,
                          720,
                          672,
                          729,
                          671,
                          732,
                          670,
                          747,
                          667,
                          767,
                          666,
                          776,
                          666,
                          786,
                          665,
                          789,
                          664,
                          793,
                          542,
                          793,
                          541,
                          792,
                          556,
                          704,
                          570,
                          617,
                          571,
                          616,
                          689,
                          616,
                          689,
                          621,
                          687,
                          633,
                          684,
                          648,
                          682,
                          661,
                          681,
                          666
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1902,
                        "area": "1902",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          798,
                          666,
                          797,
                          682,
                          797,
                          686,
                          796,
                          693,
                          796,
                          696,
                          795,
                          708,
                          795,
                          713,
                          794,
                          723,
                          793,
                          733,
                          793,
                          737,
                          792,
                          753,
                          790,
                          776,
                          781,
                          776,
                          781,
                          786,
                          781,
                          789,
                          781,
                          793,
                          660,
                          793,
                          662,
                          793,
                          664,
                          793,
                          676,
                          705,
                          689,
                          617,
                          689,
                          616,
                          802,
                          616,
                          801,
                          621,
                          800,
                          634,
                          799,
                          648,
                          798,
                          661,
                          798,
                          666
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A2",
                        "type": {
                          "id": 2,
                          "shortCode": "A2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1903,
                        "area": "1903",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1018,
                          776,
                          904,
                          776,
                          790,
                          776,
                          796,
                          696,
                          802,
                          616,
                          908,
                          616,
                          1014,
                          616,
                          1016,
                          696,
                          1018,
                          776
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B1",
                        "type": {
                          "id": 3,
                          "shortCode": "B1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1904,
                        "area": "1904",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1246,
                          776,
                          1132,
                          776,
                          1018,
                          776,
                          1016,
                          696,
                          1014,
                          616,
                          1119,
                          616,
                          1225,
                          616,
                          1236,
                          696,
                          1246,
                          776
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B2",
                        "type": {
                          "id": 4,
                          "shortCode": "B2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1905,
                        "area": "1905",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1477,
                          776,
                          1361,
                          776,
                          1246,
                          776,
                          1236,
                          696,
                          1225,
                          616,
                          1331,
                          616,
                          1437,
                          616,
                          1457,
                          696,
                          1477,
                          776
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B3",
                        "type": {
                          "id": 5,
                          "shortCode": "B3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1906,
                        "area": "1906",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          793,
                          583,
                          797,
                          595,
                          698,
                          595,
                          598,
                          595,
                          579,
                          583,
                          596,
                          484,
                          658,
                          484,
                          658,
                          478,
                          663,
                          478,
                          668,
                          484,
                          668,
                          476,
                          728,
                          476,
                          737,
                          490,
                          768,
                          490,
                          768,
                          484,
                          799,
                          484,
                          793,
                          575,
                          793,
                          583
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C1",
                        "type": {
                          "id": 6,
                          "shortCode": "C1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1907,
                        "area": "1907",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1005,
                          583,
                          1005,
                          595,
                          901,
                          595,
                          797,
                          595,
                          793,
                          583,
                          793,
                          575,
                          799,
                          484,
                          823,
                          484,
                          826,
                          487,
                          848,
                          487,
                          848,
                          485,
                          865,
                          485,
                          865,
                          476,
                          924,
                          476,
                          924,
                          489,
                          965,
                          489,
                          965,
                          484,
                          995,
                          484,
                          995,
                          479,
                          1003,
                          479,
                          1004,
                          575,
                          1005,
                          583
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C2",
                        "type": {
                          "id": 7,
                          "shortCode": "C2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 1908,
                        "area": "1908",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1429,
                          584,
                          1406,
                          596,
                          1327,
                          596,
                          1249,
                          595,
                          1247,
                          587,
                          1241,
                          587,
                          1231,
                          595,
                          1231,
                          595,
                          1205,
                          595,
                          1212,
                          582,
                          1211,
                          575,
                          1199,
                          478,
                          1206,
                          478,
                          1206,
                          481,
                          1236,
                          481,
                          1236,
                          486,
                          1264,
                          486,
                          1277,
                          474,
                          1335,
                          474,
                          1337,
                          480,
                          1407,
                          480,
                          1429,
                          584
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C3",
                        "type": {
                          "id": 8,
                          "shortCode": "C3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 20,
                    "building_id": 1,
                    "level": 20,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 2001,
                        "area": "2001",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          689,
                          615,
                          679,
                          692,
                          669,
                          769,
                          668,
                          780,
                          667,
                          789,
                          540,
                          789,
                          555,
                          701,
                          570,
                          613,
                          689,
                          615
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2002,
                        "area": "2002",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          798,
                          613,
                          793,
                          693,
                          788,
                          773,
                          780,
                          773,
                          780,
                          789,
                          667,
                          789,
                          678,
                          702,
                          689,
                          615,
                          798,
                          613
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A2",
                        "type": {
                          "id": 2,
                          "shortCode": "A2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2003,
                        "area": "2003",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1011,
                          773,
                          900,
                          773,
                          788,
                          773,
                          793,
                          693,
                          798,
                          613,
                          902,
                          613,
                          1007,
                          613,
                          1009,
                          693,
                          1011,
                          773
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B1",
                        "type": {
                          "id": 3,
                          "shortCode": "B1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2004,
                        "area": "2004",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1241,
                          773,
                          1126,
                          773,
                          1011,
                          773,
                          1009,
                          693,
                          1007,
                          613,
                          1114,
                          613,
                          1222,
                          613,
                          1232,
                          693,
                          1241,
                          773
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B2",
                        "type": {
                          "id": 4,
                          "shortCode": "B2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2005,
                        "area": "2005",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1468,
                          773,
                          1355,
                          773,
                          1241,
                          773,
                          1232,
                          693,
                          1222,
                          613,
                          1328,
                          613,
                          1435,
                          613,
                          1451,
                          693,
                          1468,
                          773
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B3",
                        "type": {
                          "id": 5,
                          "shortCode": "B3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2006,
                        "area": "2006",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          792,
                          580,
                          797,
                          592,
                          697,
                          592,
                          597,
                          592,
                          579,
                          580,
                          596,
                          481,
                          657,
                          481,
                          657,
                          475,
                          663,
                          475,
                          668,
                          481,
                          668,
                          473,
                          727,
                          473,
                          737,
                          487,
                          768,
                          487,
                          768,
                          481,
                          799,
                          481,
                          793,
                          571,
                          792,
                          580
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C1",
                        "type": {
                          "id": 6,
                          "shortCode": "C1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2007,
                        "area": "2007",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1004,
                          579,
                          1004,
                          592,
                          901,
                          592,
                          797,
                          592,
                          792,
                          580,
                          793,
                          571,
                          799,
                          481,
                          823,
                          481,
                          826,
                          484,
                          847,
                          484,
                          847,
                          482,
                          865,
                          482,
                          865,
                          473,
                          924,
                          473,
                          924,
                          486,
                          964,
                          486,
                          964,
                          481,
                          995,
                          481,
                          995,
                          476,
                          1003,
                          476,
                          1004,
                          571,
                          1004,
                          579
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C2",
                        "type": {
                          "id": 7,
                          "shortCode": "C2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2008,
                        "area": "2008",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1429,
                          580,
                          1405,
                          593,
                          1327,
                          593,
                          1249,
                          592,
                          1247,
                          584,
                          1241,
                          584,
                          1231,
                          592,
                          1231,
                          592,
                          1205,
                          592,
                          1212,
                          579,
                          1211,
                          571,
                          1199,
                          475,
                          1205,
                          475,
                          1205,
                          477,
                          1236,
                          477,
                          1236,
                          483,
                          1264,
                          483,
                          1276,
                          471,
                          1335,
                          471,
                          1336,
                          477,
                          1406,
                          477,
                          1429,
                          580
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C3",
                        "type": {
                          "id": 8,
                          "shortCode": "C3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 21,
                    "building_id": 1,
                    "level": 21,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 2101,
                        "area": "2101",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          689,
                          613,
                          679,
                          686,
                          669,
                          759,
                          667,
                          775,
                          665,
                          788,
                          543,
                          788,
                          557,
                          699,
                          572,
                          611,
                          689,
                          613
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2102,
                        "area": "2102",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          804,
                          611,
                          799,
                          689,
                          793,
                          768,
                          781,
                          768,
                          780,
                          787,
                          665,
                          788,
                          677,
                          700,
                          689,
                          613,
                          804,
                          611
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A2",
                        "type": {
                          "id": 2,
                          "shortCode": "A2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2103,
                        "area": "2103",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1015,
                          768,
                          904,
                          768,
                          793,
                          768,
                          799,
                          689,
                          804,
                          611,
                          908,
                          611,
                          1011,
                          611,
                          1013,
                          689,
                          1015,
                          768
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B1",
                        "type": {
                          "id": 3,
                          "shortCode": "B1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2104,
                        "area": "2104",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1243,
                          768,
                          1130,
                          768,
                          1016,
                          768,
                          1014,
                          689,
                          1012,
                          611,
                          1118,
                          611,
                          1224,
                          611,
                          1234,
                          689,
                          1243,
                          768
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B2",
                        "type": {
                          "id": 4,
                          "shortCode": "B2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2105,
                        "area": "2105",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1470,
                          768,
                          1357,
                          768,
                          1244,
                          768,
                          1234,
                          689,
                          1224,
                          611,
                          1331,
                          611,
                          1437,
                          611,
                          1454,
                          689,
                          1470,
                          768
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B3",
                        "type": {
                          "id": 5,
                          "shortCode": "B3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2106,
                        "area": "2106",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          793,
                          578,
                          797,
                          591,
                          698,
                          591,
                          598,
                          591,
                          579,
                          579,
                          596,
                          479,
                          658,
                          479,
                          658,
                          473,
                          663,
                          473,
                          668,
                          479,
                          668,
                          471,
                          728,
                          471,
                          737,
                          485,
                          768,
                          485,
                          768,
                          479,
                          799,
                          479,
                          793,
                          570,
                          793,
                          578
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C1",
                        "type": {
                          "id": 6,
                          "shortCode": "C1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2107,
                        "area": "2107",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1005,
                          578,
                          1005,
                          591,
                          901,
                          591,
                          797,
                          591,
                          793,
                          578,
                          793,
                          570,
                          799,
                          479,
                          823,
                          479,
                          826,
                          482,
                          848,
                          482,
                          848,
                          480,
                          865,
                          480,
                          865,
                          471,
                          924,
                          471,
                          924,
                          484,
                          965,
                          484,
                          965,
                          479,
                          995,
                          479,
                          995,
                          474,
                          1003,
                          474,
                          1004,
                          570,
                          1005,
                          578
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C2",
                        "type": {
                          "id": 7,
                          "shortCode": "C2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2108,
                        "area": "2108",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1429,
                          579,
                          1406,
                          592,
                          1327,
                          591,
                          1249,
                          591,
                          1247,
                          582,
                          1241,
                          582,
                          1231,
                          591,
                          1231,
                          591,
                          1205,
                          591,
                          1212,
                          577,
                          1211,
                          570,
                          1199,
                          474,
                          1206,
                          474,
                          1206,
                          476,
                          1236,
                          476,
                          1236,
                          482,
                          1264,
                          482,
                          1277,
                          469,
                          1335,
                          469,
                          1337,
                          476,
                          1407,
                          476,
                          1429,
                          579
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C3",
                        "type": {
                          "id": 8,
                          "shortCode": "C3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 22,
                    "building_id": 1,
                    "level": 22,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 2201,
                        "area": "2201",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          690,
                          607,
                          672,
                          743,
                          669,
                          764,
                          666,
                          784,
                          543,
                          784,
                          558,
                          696,
                          573,
                          607,
                          690,
                          607
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2202,
                        "area": "2202",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          801,
                          607,
                          796,
                          689,
                          791,
                          770,
                          781,
                          770,
                          780,
                          784,
                          666,
                          784,
                          678,
                          696,
                          690,
                          607,
                          801,
                          607
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A2",
                        "type": {
                          "id": 2,
                          "shortCode": "A2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2203,
                        "area": "2203",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1018,
                          770,
                          904,
                          770,
                          791,
                          770,
                          796,
                          689,
                          801,
                          607,
                          907,
                          607,
                          1013,
                          607,
                          1016,
                          689,
                          1018,
                          770
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B1",
                        "type": {
                          "id": 3,
                          "shortCode": "B1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2204,
                        "area": "2204",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1244,
                          770,
                          1131,
                          770,
                          1018,
                          770,
                          1016,
                          689,
                          1014,
                          607,
                          1119,
                          607,
                          1225,
                          607,
                          1235,
                          689,
                          1244,
                          770
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B2",
                        "type": {
                          "id": 4,
                          "shortCode": "B2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2205,
                        "area": "2205",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1474,
                          770,
                          1359,
                          770,
                          1244,
                          770,
                          1235,
                          689,
                          1225,
                          607,
                          1331,
                          607,
                          1437,
                          607,
                          1456,
                          689,
                          1474,
                          770
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B3",
                        "type": {
                          "id": 5,
                          "shortCode": "B3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2206,
                        "area": "2206",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          793,
                          574,
                          798,
                          587,
                          698,
                          587,
                          598,
                          587,
                          580,
                          575,
                          596,
                          475,
                          658,
                          475,
                          658,
                          469,
                          663,
                          469,
                          668,
                          475,
                          668,
                          468,
                          728,
                          468,
                          738,
                          481,
                          768,
                          481,
                          768,
                          476,
                          800,
                          476,
                          794,
                          566,
                          793,
                          574
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C1",
                        "type": {
                          "id": 6,
                          "shortCode": "C1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2207,
                        "area": "2207",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1005,
                          574,
                          1005,
                          587,
                          901,
                          587,
                          798,
                          587,
                          793,
                          574,
                          794,
                          566,
                          800,
                          476,
                          824,
                          476,
                          827,
                          479,
                          848,
                          479,
                          848,
                          476,
                          865,
                          476,
                          865,
                          468,
                          925,
                          468,
                          925,
                          480,
                          965,
                          480,
                          965,
                          475,
                          995,
                          475,
                          995,
                          470,
                          1004,
                          470,
                          1005,
                          566,
                          1005,
                          574
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C2",
                        "type": {
                          "id": 7,
                          "shortCode": "C2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2208,
                        "area": "2208",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1429,
                          575,
                          1406,
                          588,
                          1328,
                          587,
                          1249,
                          587,
                          1247,
                          578,
                          1242,
                          578,
                          1231,
                          587,
                          1231,
                          587,
                          1206,
                          587,
                          1212,
                          574,
                          1211,
                          566,
                          1199,
                          470,
                          1206,
                          470,
                          1206,
                          472,
                          1236,
                          472,
                          1236,
                          478,
                          1265,
                          478,
                          1277,
                          465,
                          1336,
                          465,
                          1337,
                          472,
                          1407,
                          472,
                          1429,
                          575
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C3",
                        "type": {
                          "id": 8,
                          "shortCode": "C3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 23,
                    "building_id": 1,
                    "level": 23,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 2301,
                        "area": "2301",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          690,
                          605,
                          675,
                          730,
                          672,
                          757,
                          667,
                          781,
                          543,
                          781,
                          557,
                          693,
                          572,
                          605,
                          690,
                          605
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2302,
                        "area": "2302",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          800,
                          605,
                          795,
                          684,
                          790,
                          763,
                          781,
                          763,
                          780,
                          781,
                          667,
                          781,
                          679,
                          693,
                          690,
                          605,
                          800,
                          605
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A2",
                        "type": {
                          "id": 2,
                          "shortCode": "A2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2303,
                        "area": "2303",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1018,
                          763,
                          904,
                          763,
                          790,
                          763,
                          795,
                          684,
                          800,
                          605,
                          907,
                          605,
                          1013,
                          605,
                          1016,
                          684,
                          1018,
                          763
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B1",
                        "type": {
                          "id": 3,
                          "shortCode": "B1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2304,
                        "area": "2304",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1243,
                          763,
                          1130,
                          763,
                          1018,
                          763,
                          1016,
                          684,
                          1013,
                          605,
                          1119,
                          605,
                          1224,
                          605,
                          1234,
                          684,
                          1243,
                          763
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B2",
                        "type": {
                          "id": 4,
                          "shortCode": "B2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2305,
                        "area": "2305",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1470,
                          763,
                          1357,
                          763,
                          1244,
                          763,
                          1234,
                          684,
                          1224,
                          605,
                          1331,
                          605,
                          1437,
                          605,
                          1454,
                          684,
                          1470,
                          763
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B3",
                        "type": {
                          "id": 5,
                          "shortCode": "B3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2306,
                        "area": "2306",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          793,
                          573,
                          797,
                          585,
                          698,
                          585,
                          598,
                          585,
                          579,
                          573,
                          596,
                          474,
                          658,
                          474,
                          658,
                          468,
                          663,
                          468,
                          668,
                          474,
                          668,
                          466,
                          728,
                          466,
                          737,
                          480,
                          768,
                          480,
                          768,
                          474,
                          799,
                          474,
                          793,
                          565,
                          793,
                          573
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C1",
                        "type": {
                          "id": 6,
                          "shortCode": "C1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2307,
                        "area": "2307",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1005,
                          573,
                          1005,
                          586,
                          901,
                          585,
                          797,
                          585,
                          793,
                          573,
                          793,
                          565,
                          799,
                          474,
                          823,
                          474,
                          826,
                          477,
                          848,
                          477,
                          848,
                          475,
                          865,
                          475,
                          865,
                          466,
                          924,
                          466,
                          924,
                          479,
                          965,
                          479,
                          965,
                          474,
                          995,
                          474,
                          995,
                          469,
                          1003,
                          469,
                          1004,
                          565,
                          1005,
                          573
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C2",
                        "type": {
                          "id": 7,
                          "shortCode": "C2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2308,
                        "area": "2308",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1429,
                          574,
                          1406,
                          587,
                          1327,
                          586,
                          1249,
                          586,
                          1247,
                          577,
                          1241,
                          577,
                          1231,
                          586,
                          1231,
                          586,
                          1205,
                          586,
                          1212,
                          572,
                          1211,
                          565,
                          1199,
                          469,
                          1206,
                          469,
                          1206,
                          471,
                          1236,
                          471,
                          1236,
                          477,
                          1264,
                          477,
                          1277,
                          464,
                          1335,
                          464,
                          1337,
                          471,
                          1407,
                          470,
                          1429,
                          574
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C3",
                        "type": {
                          "id": 8,
                          "shortCode": "C3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 24,
                    "building_id": 1,
                    "level": 24,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 2401,
                        "area": "2401",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          689,
                          603,
                          672,
                          741,
                          670,
                          759,
                          668,
                          780,
                          541,
                          780,
                          556,
                          691,
                          570,
                          603,
                          689,
                          603
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2402,
                        "area": "2402",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          799,
                          603,
                          794,
                          681,
                          789,
                          759,
                          783,
                          759,
                          781,
                          780,
                          668,
                          780,
                          679,
                          691,
                          689,
                          603,
                          799,
                          603
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A2",
                        "type": {
                          "id": 2,
                          "shortCode": "A2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2403,
                        "area": "2403",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1011,
                          759,
                          900,
                          759,
                          789,
                          759,
                          794,
                          681,
                          799,
                          603,
                          903,
                          603,
                          1007,
                          603,
                          1009,
                          681,
                          1011,
                          759
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B1",
                        "type": {
                          "id": 3,
                          "shortCode": "B1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2404,
                        "area": "2404",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1241,
                          759,
                          1126,
                          759,
                          1011,
                          759,
                          1009,
                          681,
                          1007,
                          603,
                          1115,
                          603,
                          1222,
                          603,
                          1232,
                          681,
                          1241,
                          759
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B2",
                        "type": {
                          "id": 4,
                          "shortCode": "B2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2405,
                        "area": "2405",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1469,
                          759,
                          1355,
                          759,
                          1242,
                          759,
                          1232,
                          681,
                          1222,
                          603,
                          1329,
                          603,
                          1435,
                          603,
                          1452,
                          681,
                          1469,
                          759
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B3",
                        "type": {
                          "id": 5,
                          "shortCode": "B3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2406,
                        "area": "2406",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          793,
                          570,
                          797,
                          582,
                          698,
                          582,
                          598,
                          582,
                          579,
                          570,
                          596,
                          471,
                          658,
                          471,
                          658,
                          465,
                          663,
                          465,
                          668,
                          471,
                          668,
                          463,
                          728,
                          463,
                          737,
                          477,
                          768,
                          477,
                          768,
                          471,
                          799,
                          471,
                          793,
                          562,
                          793,
                          570
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C1",
                        "type": {
                          "id": 6,
                          "shortCode": "C1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2407,
                        "area": "2407",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1005,
                          570,
                          1005,
                          582,
                          901,
                          582,
                          797,
                          582,
                          793,
                          570,
                          793,
                          562,
                          799,
                          471,
                          823,
                          471,
                          826,
                          474,
                          848,
                          474,
                          848,
                          472,
                          865,
                          472,
                          865,
                          463,
                          924,
                          463,
                          924,
                          476,
                          965,
                          476,
                          965,
                          471,
                          995,
                          471,
                          995,
                          466,
                          1003,
                          466,
                          1004,
                          562,
                          1005,
                          570
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C2",
                        "type": {
                          "id": 7,
                          "shortCode": "C2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2408,
                        "area": "2408",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1429,
                          571,
                          1406,
                          583,
                          1327,
                          583,
                          1249,
                          582,
                          1247,
                          574,
                          1241,
                          574,
                          1231,
                          582,
                          1231,
                          582,
                          1205,
                          582,
                          1212,
                          569,
                          1211,
                          562,
                          1199,
                          465,
                          1206,
                          465,
                          1206,
                          468,
                          1236,
                          468,
                          1236,
                          473,
                          1264,
                          473,
                          1277,
                          461,
                          1335,
                          461,
                          1337,
                          467,
                          1407,
                          467,
                          1429,
                          571
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C3",
                        "type": {
                          "id": 8,
                          "shortCode": "C3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 25,
                    "building_id": 1,
                    "level": 25,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 2501,
                        "area": "2501",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          689,
                          600,
                          673,
                          743,
                          671,
                          761,
                          669,
                          776,
                          544,
                          776,
                          559,
                          688,
                          574,
                          600,
                          689,
                          600
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2502,
                        "area": "2502",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          802,
                          600,
                          797,
                          680,
                          792,
                          761,
                          783,
                          761,
                          781,
                          776,
                          669,
                          776,
                          679,
                          688,
                          689,
                          600,
                          802,
                          600
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A2",
                        "type": {
                          "id": 2,
                          "shortCode": "A2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2503,
                        "area": "2503",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1015,
                          761,
                          904,
                          761,
                          792,
                          761,
                          797,
                          680,
                          802,
                          600,
                          906,
                          600,
                          1011,
                          600,
                          1013,
                          680,
                          1015,
                          761
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B1",
                        "type": {
                          "id": 3,
                          "shortCode": "B1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2504,
                        "area": "2504",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1243,
                          761,
                          1129,
                          761,
                          1015,
                          761,
                          1013,
                          680,
                          1011,
                          600,
                          1117,
                          600,
                          1223,
                          600,
                          1233,
                          680,
                          1243,
                          761
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B2",
                        "type": {
                          "id": 4,
                          "shortCode": "B2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2505,
                        "area": "2505",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1469,
                          761,
                          1356,
                          761,
                          1242,
                          761,
                          1233,
                          680,
                          1223,
                          600,
                          1329,
                          600,
                          1436,
                          600,
                          1452,
                          680,
                          1469,
                          761
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B3",
                        "type": {
                          "id": 5,
                          "shortCode": "B3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2506,
                        "area": "2506",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          792,
                          568,
                          797,
                          580,
                          697,
                          580,
                          597,
                          580,
                          579,
                          568,
                          596,
                          469,
                          657,
                          469,
                          657,
                          463,
                          663,
                          463,
                          668,
                          469,
                          668,
                          461,
                          727,
                          461,
                          737,
                          474,
                          768,
                          474,
                          768,
                          469,
                          799,
                          469,
                          793,
                          559,
                          792,
                          568
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C1",
                        "type": {
                          "id": 6,
                          "shortCode": "C1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2507,
                        "area": "2507",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1004,
                          567,
                          1004,
                          580,
                          901,
                          580,
                          797,
                          580,
                          792,
                          568,
                          793,
                          559,
                          799,
                          469,
                          823,
                          469,
                          826,
                          472,
                          847,
                          472,
                          847,
                          470,
                          865,
                          470,
                          865,
                          461,
                          924,
                          461,
                          924,
                          474,
                          964,
                          474,
                          964,
                          469,
                          995,
                          469,
                          995,
                          463,
                          1003,
                          463,
                          1004,
                          559,
                          1004,
                          567
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C2",
                        "type": {
                          "id": 7,
                          "shortCode": "C2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2508,
                        "area": "2508",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1428,
                          566,
                          1405,
                          581,
                          1327,
                          582,
                          1249,
                          582,
                          1247,
                          572,
                          1241,
                          572,
                          1231,
                          580,
                          1231,
                          580,
                          1205,
                          580,
                          1212,
                          567,
                          1211,
                          559,
                          1199,
                          463,
                          1205,
                          463,
                          1205,
                          465,
                          1236,
                          465,
                          1236,
                          471,
                          1264,
                          471,
                          1276,
                          459,
                          1335,
                          459,
                          1336,
                          465,
                          1406,
                          465,
                          1428,
                          566
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C3",
                        "type": {
                          "id": 8,
                          "shortCode": "C3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 26,
                    "building_id": 1,
                    "level": 26,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 2601,
                        "area": "2601",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          691,
                          598,
                          673,
                          742,
                          670,
                          759,
                          669,
                          776,
                          544,
                          776,
                          559,
                          687,
                          574,
                          598,
                          691,
                          598
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2602,
                        "area": "2602",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          802,
                          598,
                          797,
                          677,
                          792,
                          756,
                          782,
                          756,
                          782,
                          776,
                          669,
                          776,
                          680,
                          687,
                          691,
                          598,
                          802,
                          598
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A2",
                        "type": {
                          "id": 2,
                          "shortCode": "A2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2603,
                        "area": "2603",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1015,
                          756,
                          903,
                          756,
                          792,
                          756,
                          797,
                          677,
                          802,
                          598,
                          906,
                          598,
                          1011,
                          598,
                          1013,
                          677,
                          1015,
                          756
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B1",
                        "type": {
                          "id": 3,
                          "shortCode": "B1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2604,
                        "area": "2604",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1243,
                          756,
                          1129,
                          756,
                          1015,
                          756,
                          1013,
                          677,
                          1011,
                          598,
                          1117,
                          598,
                          1223,
                          598,
                          1233,
                          677,
                          1243,
                          756
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B2",
                        "type": {
                          "id": 4,
                          "shortCode": "B2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2605,
                        "area": "2605",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1469,
                          753,
                          1356,
                          754,
                          1243,
                          756,
                          1233,
                          677,
                          1223,
                          598,
                          1329,
                          598,
                          1436,
                          598,
                          1452,
                          675,
                          1469,
                          753
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B3",
                        "type": {
                          "id": 5,
                          "shortCode": "B3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2606,
                        "area": "2606",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          793,
                          565,
                          797,
                          578,
                          698,
                          578,
                          598,
                          578,
                          579,
                          566,
                          596,
                          464,
                          658,
                          464,
                          658,
                          458,
                          663,
                          458,
                          668,
                          464,
                          668,
                          456,
                          728,
                          456,
                          737,
                          470,
                          768,
                          470,
                          768,
                          465,
                          799,
                          465,
                          793,
                          557,
                          793,
                          565
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C1",
                        "type": {
                          "id": 6,
                          "shortCode": "C1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2607,
                        "area": "2607",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1005,
                          565,
                          1005,
                          578,
                          901,
                          578,
                          797,
                          578,
                          793,
                          565,
                          793,
                          557,
                          799,
                          465,
                          823,
                          465,
                          826,
                          468,
                          848,
                          468,
                          848,
                          465,
                          865,
                          465,
                          865,
                          456,
                          924,
                          456,
                          924,
                          469,
                          965,
                          469,
                          965,
                          464,
                          995,
                          464,
                          995,
                          459,
                          1003,
                          459,
                          1004,
                          557,
                          1005,
                          565
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C2",
                        "type": {
                          "id": 7,
                          "shortCode": "C2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2608,
                        "area": "2608",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1429,
                          566,
                          1406,
                          579,
                          1327,
                          579,
                          1249,
                          580,
                          1247,
                          569,
                          1241,
                          569,
                          1231,
                          578,
                          1231,
                          578,
                          1205,
                          578,
                          1212,
                          564,
                          1211,
                          557,
                          1199,
                          459,
                          1206,
                          459,
                          1206,
                          461,
                          1236,
                          461,
                          1236,
                          467,
                          1264,
                          467,
                          1277,
                          454,
                          1335,
                          454,
                          1337,
                          461,
                          1407,
                          461,
                          1429,
                          566
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C3",
                        "type": {
                          "id": 8,
                          "shortCode": "C3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 27,
                    "building_id": 1,
                    "level": 27,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 2701,
                        "area": "2701",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          689,
                          596,
                          673,
                          730,
                          671,
                          756,
                          669,
                          773,
                          544,
                          773,
                          559,
                          684,
                          574,
                          596,
                          689,
                          596
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A1",
                        "type": {
                          "id": 1,
                          "shortCode": "A1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2702,
                        "area": "2702",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          802,
                          596,
                          797,
                          674,
                          792,
                          753,
                          782,
                          753,
                          781,
                          773,
                          669,
                          773,
                          679,
                          684,
                          689,
                          596,
                          802,
                          596
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "A2",
                        "type": {
                          "id": 2,
                          "shortCode": "A2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2703,
                        "area": "2703",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1015,
                          753,
                          904,
                          753,
                          792,
                          753,
                          797,
                          674,
                          802,
                          596,
                          906,
                          596,
                          1011,
                          596,
                          1013,
                          674,
                          1015,
                          753
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B1",
                        "type": {
                          "id": 3,
                          "shortCode": "B1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2704,
                        "area": "2704",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1243,
                          753,
                          1129,
                          753,
                          1015,
                          753,
                          1013,
                          674,
                          1011,
                          596,
                          1117,
                          596,
                          1223,
                          596,
                          1233,
                          674,
                          1243,
                          753
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B2",
                        "type": {
                          "id": 4,
                          "shortCode": "B2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2705,
                        "area": "2705",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1469,
                          753,
                          1356,
                          753,
                          1243,
                          753,
                          1233,
                          674,
                          1223,
                          596,
                          1329,
                          596,
                          1436,
                          596,
                          1452,
                          674,
                          1469,
                          753
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "B3",
                        "type": {
                          "id": 5,
                          "shortCode": "B3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2706,
                        "area": "2706",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          793,
                          563,
                          797,
                          576,
                          698,
                          576,
                          598,
                          576,
                          579,
                          564,
                          596,
                          464,
                          658,
                          464,
                          658,
                          458,
                          663,
                          458,
                          668,
                          464,
                          668,
                          456,
                          728,
                          456,
                          737,
                          470,
                          768,
                          470,
                          768,
                          464,
                          799,
                          464,
                          793,
                          555,
                          793,
                          563
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C1",
                        "type": {
                          "id": 6,
                          "shortCode": "C1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2707,
                        "area": "2707",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1005,
                          563,
                          1005,
                          576,
                          901,
                          576,
                          797,
                          576,
                          793,
                          563,
                          793,
                          555,
                          799,
                          464,
                          823,
                          464,
                          826,
                          467,
                          848,
                          467,
                          848,
                          465,
                          865,
                          465,
                          865,
                          456,
                          924,
                          456,
                          924,
                          469,
                          965,
                          469,
                          965,
                          464,
                          995,
                          464,
                          995,
                          459,
                          1003,
                          459,
                          1004,
                          555,
                          1005,
                          563
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C2",
                        "type": {
                          "id": 7,
                          "shortCode": "C2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2708,
                        "area": "2708",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1428,
                          562,
                          1406,
                          577,
                          1327,
                          577,
                          1249,
                          578,
                          1247,
                          567,
                          1241,
                          567,
                          1231,
                          576,
                          1231,
                          576,
                          1205,
                          576,
                          1212,
                          562,
                          1211,
                          555,
                          1199,
                          459,
                          1206,
                          459,
                          1206,
                          461,
                          1236,
                          461,
                          1236,
                          467,
                          1264,
                          467,
                          1277,
                          454,
                          1335,
                          454,
                          1337,
                          461,
                          1407,
                          461,
                          1428,
                          562
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "C3",
                        "type": {
                          "id": 8,
                          "shortCode": "C3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },/*
                  {
                    "id": 28,
                    "building_id": 1,
                    "level": 28,
                    "name": "Amenidades",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 2801,
                        "area": "AMENIDADES",
                        "status": "1",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          544,455,1468,773
                        ],
                        "shape": "rect",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "FL-28",
                        "type": {
                          "id": 1,
                          "shortCode": "FL-28",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null,
                          "gallery": [
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-1.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-1.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-2.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-2.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-3.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-3.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-4.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-4.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-5.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-5.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-6.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-6.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-7.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-7.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-8.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-8.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-9.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-9.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-10.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-10.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-11.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-11.jpg',
                              width: 320,
                              height: 174,
                            },
                          ]
                        }
                      }
                    ]
                  },*/
                  {
                    "id": 28,
                    "building_id": 1,
                    "level": 28,
                    "name": "Amenidades",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 2801,
                        "area": "AMENIDADES",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1469,751, 1334,751, 1199,752, 1063,752, 928,752, 793,752, 792,772, 665,772, 539,772, 558,666, 577,561, 596,456, 731,457, 867,458, 1002,459, 1137,460, 1273,461, 1408,463, 1428,559, 1449,655, 1469,751
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "FL-28",
                        "type": {
                          "id": 1,
                          "shortCode": "FL-28",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null,
                          "gallery": [
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-1.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-1.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-2.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-2.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-3.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-3.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-4.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-4.jpg',
                              width: 320,
                              height: 174,
                            },
                            {
                              url: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-5.jpg',
                              original: PROJECT_DIR + 'floors-galleries/floor-28/amenidades-5.jpg',
                              width: 320,
                              height: 174,
                            },
                          ]
                        }
                      }
                    ]
                  },
                  {
                    "id": 29,
                    "building_id": 1,
                    "level": 29,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 2901,
                        "area": "2901",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          945,606, 945,744, 850,744, 756,745, 766,606, 855,606, 945,606
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D1",
                        "type": {
                          "id": 9,
                          "shortCode": "D1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2902,
                        "area": "2901-A",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1137,743, 1041,743, 945,744, 945,606, 1036,606, 1126,606, 1137,743
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D2",
                        "type": {
                          "id": 10,
                          "shortCode": "D2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2903,
                        "area": "2902",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1326,744, 1232,743, 1137,743, 1126,606, 1216,606, 1305,606, 1326,744
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D3",
                        "type": {
                          "id": 11,
                          "shortCode": "D3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2904,
                        "area": "2902-A",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1515,744, 1421,744, 1326,744, 1305,606, 1395,606, 1485,606, 1515,744
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D4",
                        "type": {
                          "id": 12,
                          "shortCode": "D4",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2905,
                        "area": "2903",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1012,427, 1012,569, 1010,585, 914,585, 914,572, 908,572, 908,585, 783,586, 771,570, 781,428, 830,427, 884,427, 923,427, 923,435, 972,435, 972,427, 1012,427
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "E1",
                        "type": {
                          "id": 13,
                          "shortCode": "E1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 2906,
                        "area": "2904",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1480,572, 1456,583, 1335,585, 1333,574, 1328,574, 1315,583, 1221,583, 1220,556, 1218,556, 1218,550, 1232,537, 1221,426, 1255,426, 1256,435, 1297,435, 1302,426, 1375,426, 1447,426, 1464,499, 1480,572
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "E2",
                        "type": {
                          "id": 14,
                          "shortCode": "E2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 30,
                    "building_id": 1,
                    "level": 30,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 3001,
                        "area": "3001",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          945,
                          600,
                          945,
                          740,
                          849,
                          741,
                          752,
                          741,
                          762,
                          600,
                          854,
                          600,
                          945,
                          600
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D1",
                        "type": {
                          "id": 9,
                          "shortCode": "D1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3002,
                        "area": "3001-A",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1141,
                          739,
                          1043,
                          739,
                          945,
                          740,
                          945,
                          600,
                          1038,
                          600,
                          1130,
                          600,
                          1141,
                          739
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D2",
                        "type": {
                          "id": 10,
                          "shortCode": "D2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3003,
                        "area": "3002",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1333,
                          740,
                          1237,
                          739,
                          1141,
                          739,
                          1130,
                          600,
                          1221,
                          600,
                          1312,
                          600,
                          1333,
                          740
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D3",
                        "type": {
                          "id": 11,
                          "shortCode": "D3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3004,
                        "area": "3002-A",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1532,
                          740,
                          1433,
                          740,
                          1333,
                          740,
                          1312,
                          600,
                          1402,
                          600,
                          1493,
                          600,
                          1512,
                          670,
                          1532,
                          740
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D4",
                        "type": {
                          "id": 12,
                          "shortCode": "D4",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3005,
                        "area": "3003",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1012,
                          420,
                          1012,
                          494,
                          1012,
                          568,
                          1009,
                          579,
                          912,
                          579,
                          912,
                          565,
                          907,
                          565,
                          907,
                          579,
                          778,
                          580,
                          767,
                          563,
                          777,
                          421,
                          828,
                          420,
                          828,
                          424,
                          882,
                          424,
                          882,
                          420,
                          921,
                          420,
                          921,
                          428,
                          970,
                          428,
                          970,
                          420,
                          1012,
                          420
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "E1",
                        "type": {
                          "id": 13,
                          "shortCode": "E1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3006,
                        "area": "3004",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1483,
                          565,
                          1459,
                          576,
                          1341,
                          578,
                          1340,
                          568,
                          1334,
                          568,
                          1321,
                          576,
                          1225,
                          576,
                          1224,
                          552,
                          1221,
                          552,
                          1221,
                          545,
                          1235,
                          530,
                          1224,
                          419,
                          1259,
                          419,
                          1259,
                          428,
                          1303,
                          428,
                          1309,
                          419,
                          1450,
                          419,
                          1467,
                          492,
                          1483,
                          565
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "E2",
                        "type": {
                          "id": 14,
                          "shortCode": "E2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 31,
                    "building_id": 1,
                    "level": 31,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 3101,
                        "area": "3101",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          947,
                          596,
                          947,
                          736,
                          848,
                          737,
                          749,
                          737,
                          761,
                          596,
                          854,
                          596,
                          947,
                          596
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D1",
                        "type": {
                          "id": 9,
                          "shortCode": "D1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3102,
                        "area": "3101-A",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1141,
                          735,
                          1044,
                          735,
                          947,
                          736,
                          947,
                          596,
                          1039,
                          596,
                          1130,
                          596,
                          1141,
                          735
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D2",
                        "type": {
                          "id": 10,
                          "shortCode": "D2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3103,
                        "area": "3102",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1333,
                          736,
                          1237,
                          735,
                          1141,
                          735,
                          1130,
                          596,
                          1221,
                          596,
                          1312,
                          596,
                          1333,
                          736
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D3",
                        "type": {
                          "id": 11,
                          "shortCode": "D3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3104,
                        "area": "3102-A",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1532,
                          736,
                          1433,
                          736,
                          1333,
                          736,
                          1312,
                          596,
                          1406,
                          596,
                          1500,
                          596,
                          1532,
                          736
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D4",
                        "type": {
                          "id": 12,
                          "shortCode": "D4",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3105,
                        "area": "3103",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1012,
                          413,
                          1012,
                          489,
                          1012,
                          564,
                          1009,
                          575,
                          912,
                          575,
                          912,
                          561,
                          907,
                          561,
                          907,
                          575,
                          778,
                          576,
                          763,
                          558,
                          770,
                          486,
                          777,
                          414,
                          828,
                          414,
                          828,
                          417,
                          882,
                          417,
                          882,
                          413,
                          921,
                          413,
                          921,
                          421,
                          970,
                          421,
                          970,
                          413,
                          1012,
                          413
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "E1",
                        "type": {
                          "id": 13,
                          "shortCode": "E1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3106,
                        "area": "3104",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1491,
                          558,
                          1463,
                          572,
                          1348,
                          574,
                          1345,
                          563,
                          1342,
                          563,
                          1338,
                          563,
                          1325,
                          572,
                          1229,
                          572,
                          1227,
                          547,
                          1225,
                          547,
                          1225,
                          537,
                          1240,
                          524,
                          1227,
                          411,
                          1263,
                          411,
                          1263,
                          420,
                          1306,
                          420,
                          1314,
                          411,
                          1455,
                          410,
                          1473,
                          484,
                          1491,
                          558
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "E2",
                        "type": {
                          "id": 14,
                          "shortCode": "E2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 32,
                    "building_id": 1,
                    "level": 32,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 3201,
                        "area": "3201",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          945,
                          593,
                          945,
                          734,
                          847,
                          734,
                          749,
                          735,
                          761,
                          593,
                          853,
                          593,
                          945,
                          593
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D1",
                        "type": {
                          "id": 9,
                          "shortCode": "D1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3202,
                        "area": "3201-A",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          945,
                          593,
                          1141,
                          734
                        ],
                        "shape": "rect",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D2",
                        "type": {
                          "id": 10,
                          "shortCode": "D2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3203,
                        "area": "3202",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1333,
                          734,
                          1237,
                          733,
                          1141,
                          733,
                          1130,
                          593,
                          1221,
                          593,
                          1312,
                          593,
                          1333,
                          734
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D3",
                        "type": {
                          "id": 11,
                          "shortCode": "D3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3204,
                        "area": "3202-A",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1530,
                          734,
                          1432,
                          734,
                          1333,
                          734,
                          1312,
                          593,
                          1407,
                          593,
                          1501,
                          593,
                          1530,
                          734
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D4",
                        "type": {
                          "id": 12,
                          "shortCode": "D4",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3205,
                        "area": "3203",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1012,
                          410,
                          1012,
                          485,
                          1012,
                          559,
                          1009,
                          570,
                          912,
                          570,
                          912,
                          556,
                          907,
                          556,
                          907,
                          570,
                          772,
                          571,
                          763,
                          560,
                          770,
                          486,
                          777,
                          411,
                          894,
                          411,
                          1012,
                          410
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "E1",
                        "type": {
                          "id": 13,
                          "shortCode": "E1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3206,
                        "area": "3204",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1488,
                          555,
                          1461,
                          570,
                          1346,
                          570,
                          1345,
                          556,
                          1341,
                          556,
                          1335,
                          560,
                          1320,
                          570,
                          1227,
                          570,
                          1226,
                          546,
                          1224,
                          546,
                          1224,
                          538,
                          1240,
                          523,
                          1227,
                          408,
                          1341,
                          408,
                          1455,
                          407,
                          1472,
                          481,
                          1488,
                          555
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "E2",
                        "type": {
                          "id": 14,
                          "shortCode": "E2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 33,
                    "building_id": 1,
                    "level": 33,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 3301,
                        "area": "3301",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          945,
                          591,
                          945,
                          734,
                          854,
                          734,
                          764,
                          734,
                          764,
                          730,
                          751,
                          730,
                          762,
                          591,
                          854,
                          591,
                          945,
                          591
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D1",
                        "type": {
                          "id": 9,
                          "shortCode": "D1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3302,
                        "area": "3301-A",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1141,
                          733,
                          1043,
                          733,
                          945,
                          734,
                          945,
                          591,
                          1038,
                          591,
                          1130,
                          591,
                          1141,
                          733
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D2",
                        "type": {
                          "id": 10,
                          "shortCode": "D2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3303,
                        "area": "3302",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1334,
                          732,
                          1238,
                          732,
                          1141,
                          733,
                          1130,
                          591,
                          1221,
                          591,
                          1312,
                          591,
                          1334,
                          732
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D3",
                        "type": {
                          "id": 11,
                          "shortCode": "D3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3304,
                        "area": "3302-A",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1530,
                          731,
                          1432,
                          732,
                          1334,
                          732,
                          1312,
                          591,
                          1407,
                          591,
                          1501,
                          591,
                          1530,
                          731
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D4",
                        "type": {
                          "id": 12,
                          "shortCode": "D4",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3305,
                        "area": "3303",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1014,
                          406,
                          1014,
                          480,
                          1014,
                          553,
                          1010,
                          567,
                          914,
                          567,
                          914,
                          553,
                          908,
                          553,
                          908,
                          567,
                          775,
                          567,
                          765,
                          554,
                          771,
                          481,
                          777,
                          409,
                          895,
                          407,
                          1014,
                          406
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "E1",
                        "type": {
                          "id": 13,
                          "shortCode": "E1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3306,
                        "area": "3304",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1488,
                          553,
                          1461,
                          567,
                          1346,
                          567,
                          1345,
                          554,
                          1341,
                          554,
                          1335,
                          558,
                          1321,
                          567,
                          1227,
                          567,
                          1226,
                          540,
                          1224,
                          540,
                          1224,
                          535,
                          1240,
                          518,
                          1227,
                          408,
                          1341,
                          408,
                          1455,
                          407,
                          1472,
                          480,
                          1488,
                          553
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "E2",
                        "type": {
                          "id": 14,
                          "shortCode": "E2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 34,
                    "building_id": 1,
                    "level": 34,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": [
                      {
                        "id": 3401,
                        "area": "3401",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          945,
                          591,
                          945,
                          734,
                          854,
                          734,
                          764,
                          734,
                          764,
                          730,
                          751,
                          730,
                          762,
                          591,
                          854,
                          591,
                          945,
                          591
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D1",
                        "type": {
                          "id": 9,
                          "shortCode": "D1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3402,
                        "area": "3401-A",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1141,
                          733,
                          1043,
                          733,
                          945,
                          734,
                          945,
                          591,
                          1038,
                          591,
                          1130,
                          591,
                          1141,
                          733
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D2",
                        "type": {
                          "id": 10,
                          "shortCode": "D2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3403,
                        "area": "3402",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1334,
                          732,
                          1238,
                          732,
                          1141,
                          733,
                          1130,
                          591,
                          1221,
                          591,
                          1312,
                          591,
                          1334,
                          732
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D3",
                        "type": {
                          "id": 11,
                          "shortCode": "D3",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3404,
                        "area": "3402-A",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1530,
                          731,
                          1432,
                          732,
                          1334,
                          732,
                          1312,
                          591,
                          1407,
                          591,
                          1501,
                          591,
                          1530,
                          731
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "D4",
                        "type": {
                          "id": 12,
                          "shortCode": "D4",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3405,
                        "area": "3403",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1014,
                          406,
                          1014,
                          480,
                          1014,
                          553,
                          1010,
                          567,
                          914,
                          567,
                          914,
                          553,
                          908,
                          553,
                          908,
                          567,
                          778,
                          567,
                          765,
                          554,
                          771,
                          481,
                          777,
                          409,
                          895,
                          407,
                          1014,
                          406
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "E1",
                        "type": {
                          "id": 13,
                          "shortCode": "E1",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      },
                      {
                        "id": 3406,
                        "area": "3404",
                        "status": "0",
                        "surface": "",
                        "roof_garden": null,
                        "terrace": "",
                        "coords": [
                          1488,
                          553,
                          1461,
                          567,
                          1346,
                          567,
                          1345,
                          554,
                          1341,
                          554,
                          1335,
                          558,
                          1321,
                          567,
                          1227,
                          567,
                          1226,
                          540,
                          1224,
                          540,
                          1224,
                          535,
                          1240,
                          518,
                          1227,
                          408,
                          1341,
                          408,
                          1455,
                          407,
                          1472,
                          480,
                          1488,
                          553
                        ],
                        "shape": "poly",
                        "price_formatted": "0.00",
                        "description": "",
                        "panorama": "",
                        "type_code": "E2",
                        "type": {
                          "id": 14,
                          "shortCode": "E2",
                          "code": "",
                          "name": "",
                          "description": "",
                          "surface": "",
                          "terrace": "",
                          "rooms": "",
                          "bathrooms": null,
                          "created_at": null,
                          "updated_at": null
                        }
                      }
                    ]
                  },
                  {
                    "id": 35,
                    "building_id": 1,
                    "level": 35,
                    "name": "",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": []
                  },
                  {
                    "id": 36,
                    "building_id": 1,
                    "level": 36,
                    "name": "Techo",
                    "shape": null,
                    "coords": null,
                    "available_areas": null,
                    "available_esmeralda": null,
                    "available_zafiro": null,
                    "available_ambar": null,
                    "level_identifier": "",
                    "areas": []
                  },
                ]
            }
    ]
} 
export default project;