import React, { useEffect, useRef, useState } from 'react';
import './PanoViewer.scss';

const PANOLENS = require("panolens");

type Props = {
    image: string
}

const PanoViewer = ({image} : Props) => {
    const viewerRef = useRef<HTMLInputElement | null>(null);
    
    let panorama : any;
    let viewer : any;
    let control;
    
    useEffect(() => {
        viewer = new PANOLENS.Viewer({
            enableReticle: false,
            container: viewerRef.current,
            output: "console",
            viewIndicator: false,
            //autoRotate: false,
            //autoRotateSpeed: 2,
            //autoRotateActivationDuration: 5000,
            dwellTime: 2000,
            controlBar: false,

            horizontalView: true,
        });
        const preloadedImage = new Image();
        preloadedImage.src = image;
        preloadedImage.onload = () => {
            panorama = new PANOLENS.ImagePanorama(image);
            viewer.add(panorama);
        };
    },[])

    useEffect(() => {
        
        const preloadedImage = new Image();
        preloadedImage.src = image;
        preloadedImage.onload = () => {
            panorama = new PANOLENS.ImagePanorama(image);        
            viewer.setPanorama(panorama);
        };
    },[image])

    return (
        <>
            <div className='panorama-viewer' ref={viewerRef} style={{ backgroundImage: `url(${image})` }}></div>
        </>
    )
}

export default PanoViewer;